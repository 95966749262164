/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Icon } from 'fish'
import { withRouter } from 'react-router-dom'
import { AdminMenu } from 'modules/layouts/adminMenu';
import './index.less'
import AddWorkInner from './add';

@AdminMenu({ key: 'biz-course-work' })
@withRouter
class AddWork extends PureComponent {
  render() {
    return (
      <>
        <div className="_teacher_work_add_title">
          <div>
            {
              window.location.href.indexOf('add') > -1 ? '添加作业' : '编辑作业'
            }
          </div>
          <Icon
            type="close"
            onClick={() => {
              window.history.back()
            }}
          />
        </div>
        <AddWorkInner />
      </>
    )
  }
}

export default AddWork
