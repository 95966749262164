/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-script-url */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react'
import { message, Progress } from 'fish'
// import iconsStyle from '@/page/teacherStudioAdmin/subPage/resource/subPage/edit/index.module.less'
import './index.less'
// import NetImage from './netImage'

const fmSize = (size) => {
  if (size < 1000) {
    return `${size}B`
  } else if (size / 1024 < 1000) {
    return `${Number(size / 1024).toFixed(1)}KB`
  } else if (size / 1024 / 1024 < 1000) {
    return `${Number(size / 1024 / 1024).toFixed(1)}MB`
  } else if (size / 1024 / 1024 / 1024 < 1000) {
    return `${Number(size / 1024 / 1024 / 1024).toFixed(1)}GB`
  } else if (size / 1024 / 1024 / 1024 / 1024 < 1000) {
    return `${Number(size / 1024 / 1024 / 1024 / 1024).toFixed(1)}TB`
  } else if (size / 1024 / 1024 / 1024 / 1024 / 1024 < 1000) {
    return `${Number(size / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(1)}PB`
  } else if (size / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 < 1000) {
    return `${Number(size / 1024 / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(1)}EB`
  } else if (size / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 < 1000) {
    return `${Number(size / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(1)}ZB`
  } else if (size / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 < 1000) {
    return `${Number(size / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(1)}YB`
  } else {
    return '∞'
  }
}

const getType = (mediaType) => {
  switch (mediaType) {
    case 0:
      return 'image'
    case 1:
      return 'audio'
    case 2:
      return 'video'
    default:
      return 'file'
  }
}

export default (props) => {
  const { media_type, url, ext_info } = props.data

  const type = getType(media_type)
  let screenshot = url
  screenshot += `${url?.lastIndexOf('?') > -1 ? '&' : '?'}size=320`
  let name = ''
  let size = 0
  try {
    name = JSON.parse(ext_info)?.file_name
    size = JSON.parse(ext_info)?.size
  } catch (ex) {
    //
  }

  const onImagePlay = () => {
    props.onImagePlay(props.data.index)
  }

  const onVideoPlay = () => {
    props.onVideoPlay(props.data)
  }

  const onPlay = () => {
    switch (type) {
      case 'image':
        onImagePlay()
        break
      case 'video':
        onVideoPlay()
        break
      default:
        message.config({
          style: { zIndex: 9999 }
        })
        message.info('该格式不支持播放，请下载后查看')
        break
    }
  }

  const getImage = () => (
    <>
      {/* <NetImage src={screenshot} size={360} /> */}
      <img src={screenshot} />
      {
        props.edit && (
          props.data.attachment_id && (
          <div
            className="deleteIcon"
            onClick={(e) => {
              // 删除
              e.stopPropagation()
              props.onDelete(props.data)
            }}
          >
            {/* <img src={require('./img/delete.svg')} /> */}
            <svg
              fill="none"
              version="1.1"
              width="7.9550018310546875"
              height="7.9550018310546875"
              viewBox="0 0 7.9550018310546875 7.9550018310546875"
            >
              <g>
                <path
                  d="M3.9775,3.09375L7.07125,2.98023e-7L7.955,0.88375L4.86125,3.9775L7.955,7.07125L7.07125,7.955L3.9775,4.86125L0.88375,7.955L2.98023e-7,7.07125L3.09375,3.9775L0,0.883751L0.88375,0L3.9775,3.09375Z"
                  fill="#FFFFFF"
                  fillOpacity="1"
                />
              </g>
            </svg>
          </div>
          )
        )
      }
    </>
  )

  const getOther = (icon) => (
    <div className="videoDiv">
      <img src={require(`./img/${icon}.svg`)} />
      <div className="videoDivTitle">
        <span style={{
          fontSize: '12px',
          color: '#666'
        }}
        >
          {
            name
          }
        </span>
        <div className="videoDivPercent">
          <span style={{
            width: '50px',
            fontSize: '12px',
            color: '#929AA4'
          }}
          >
            {
              fmSize(size)
            }
          </span>
          {
            props.data.percent >= 0 && (props.data?.attachment_id ? (
              <span style={{
                margin: '0 20px 0 0',
                color: '#00B42A'
              }}
              >
                上传完成
              </span>
            ) : (
              <span style={{
                margin: '0 20px',
                color: '#929AA4'
              }}
              >
                上传中
              </span>
            ))
          }
          {
            props.edit && (
              <Progress
                style={{
                  width: '190px'
                }}
                percent={props.data?.percent}
                showInfo={false}
              />
            )
          }
        </div>
      </div>
      <div className="videoDivDown">
        {
          props.edit ? (
            props.data.attachment_id && (
            <div
              className="deleteIcon"
              onClick={() => {
                // 删除
                props.onDelete(props.data)
              }}
            >
              {/* <img src={require('./img/delete.svg')} /> */}
              <svg
                fill="none"
                version="1.1"
                width="7.9550018310546875"
                height="7.9550018310546875"
                viewBox="0 0 7.9550018310546875 7.9550018310546875"
              >
                <g>
                  <path
                    d="M3.9775,3.09375L7.07125,2.98023e-7L7.955,0.88375L4.86125,3.9775L7.955,7.07125L7.07125,7.955L3.9775,4.86125L0.88375,7.955L2.98023e-7,7.07125L3.09375,3.9775L0,0.883751L0.88375,0L3.9775,3.09375Z"
                    fill="#FFFFFF"
                    fillOpacity="1"
                  />
                </g>
              </svg>
            </div>
            )
          ) : (
            <div className="videoDivOper">
              <a
                onClick={onPlay}
              >
                预览
              </a>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                // href="javascript:;"
                // onClick={() => {
                //   downloadFile(url, name)
                // }}
              >
                下载
              </a>
            </div>
          )
        }
      </div>
    </div>
  )

  const getAudio = () => getOther('audio')

  const getVideo = () => getOther('video')

  const getFile = () => {
    if (name.indexOf('.pdf') > -1) {
      return getOther('pdf')
    } else if (name.indexOf('.doc') > -1) {
      return getOther('doc')
    } else if (name.indexOf('.xls') > -1) {
      return getOther('xls')
    } else if (name.indexOf('.ppt') > -1) {
      return getOther('ppt')
    } else if (name.indexOf('.zip') > -1) {
      return getOther('zip')
    } else if (name.indexOf('.txt') > -1) {
      return getOther('txt')
    } else {
      return getOther('default')
    }
  }

  const getElement = () => {
    switch (type) {
      case 'image':
        return getImage()
      case 'audio':
        return getAudio()
      case 'video':
        return getVideo()
      case 'file':
        return getFile()
      default:
        return null
    }
  }

  return (
    <>
      {
        type === 'image' ? (
          <div className="attachment" onClick={onPlay}>
            <div className={`media ${type}`}>
              {getElement()}
            </div>
          </div>
        ) : (
          <div className="attachmentOther">
            <div className={`media ${type}`}>
              {getElement()}
            </div>
          </div>
        )
      }
    </>
  )
}
