import React, { Component } from 'react';
import './index.less';

class Index extends Component {
  render() {
    const { text = '未通过审核', flag } = this.props;


    if (!flag) {
      return null;
    }

    return (
      <div className={'wrap_text'} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#F53F3F',
        position: 'absolute',
        left: 8,
        top: 8,
        width: 88,
        height: 26,
        borderRadius: 4,
        opacity: 1,
        padding: 4,
        gap: 6,
        background: '#FFECEC'
      }}>
        {text}
      </div>
    );
  }
}

export default Index;
