import React from 'react';
import PropTypes from 'prop-types';
import { AdminMenu } from 'modules/layouts/adminMenu';
import Iframe from 'modules/common/iframe';
import { uc } from 'utils/auth/func'
import { getUrlQuery } from 'utils/url'
import { elearningQaHost } from 'cfg/elearningQa'
import * as $dp from 'dataProvider';
import './style.less';
const USE_QA = 'use_qa'
@AdminMenu({ key: 'biz-course-qa' })
class Qa extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      useQa: false,
      iframeUrl: ''
    }
  }

  async componentDidMount() {
    let {
      params: { id }
    } = this.props.match;
    const courseDetail = await $dp.bizCourseGateway.detail.replace('business_course_id', id).get()
    const _useQa = courseDetail?.ext_info?.[USE_QA] || false
    this.setState({
      useQa: _useQa
    })
    if (_useQa) {
      const { activity_set_id } = getUrlQuery()
      // http://192.168.31.220:8080/
      let url = window.eduBasicLib.getAppURL(`${elearningQaHost}/qa-web/index.html#/xQuestion?custom_id=${id}&activity_set_id=${activity_set_id}&showDiscussManual=1`);
      let mac = uc.generateMac(url)
      this.setState({
        iframeUrl: `${url}&__mac=${mac}`
      })
    }
  }
  
  render() {
    const { useQa, iframeUrl } = this.state
    const noConfigTip = (
      <div className='no-data-tip'>
        当前课程未开启课程讨论，如需开启，<br></br>请联系管理员前往[更多管理]中配置
      </div>
    )
    return useQa ? <Iframe src={iframeUrl} /> : noConfigTip;
  }
}

Qa.propTypes = {
  match: PropTypes.object.isRequired
};

export default Qa;
