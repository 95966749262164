import { handleActions } from 'redux-actions';
import * as T from './actionTypes';

const morePlugins = handleActions(
  {
    [T.GET_MANAGEMENT_PLUGINS]: (state, action) => {
      return {
        ...state,
        data: action.payload ? action.payload : []
      };
    }
  },
  []
);

export { morePlugins };
