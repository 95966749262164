/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom'
import { AdminMenu } from 'modules/layouts/adminMenu';
import LoadingComponent from 'modules/common/loading';
import { Table, Divider, Button, message, Icon, Popover, Pagination } from 'fish';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import './index.less'

import { doDeleteWork, doOfflineWork, doOnlineWork, getWorkList, updateWorkOrder } from './actions';


function jumpTo(subPath) {
  window.location.href = `${window.location.origin}${subPath}`
}

function getJump(subPath) {
  return `${window.location.origin}${subPath}`
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);
const DragHandle = sortableHandle(() => <Icon type="menu" style={{ cursor: 'pointer', color: '#999' }} />);

@AdminMenu({ key: 'biz-course-work' })
@withRouter
@LoadingComponent
class Work extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      workList: [],
      current: 1,
      limit: 10,
      total: 0,
      loading: false
    }
  }

  init = (_page = this.state.current) => {
    this.setState({
      loading: true
    })
    getWorkList({
      limit: this.state.limit,
      offset: (_page - 1) * this.state.limit
    }).then((res) => {
      this.setState({
        loading: false
      })
      // 先添加排序使用字段 再进行设值
      this.setState({
        workList: res.items.map((v, idx) => ({
          ...v,
          index: idx
        })),
        total: res.total
      })
      this.props.setLoading(false);
    })
  }

  onChange = (page) => {
    this.setState({
      current: page
    })
    this.init(page)
  }

  componentDidMount() {
    this.init()
  }

  columns = [
    {
      title: '排序',
      dataIndex: 'sort',
      width: 65,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: '作业标题',
      dataIndex: 'title',
      key: 'title',
      render: text => text
    },
    {
      title: '操作',
      key: 'action',
      width: 135,
      render: (text, record) => (
        <span>
          {
            record.online === 1 ? (
              <a
                href="javascript:;"
                className="btn_online"
                onClick={() => {
                  // 下线
                  doOfflineWork(record.work_relation_id).then((res) => {
                    if (res.result === 'success') {
                      // 成功
                      message.success('下线成功')
                      this.init()
                    } else {
                      message.error(`下线失败:${res.message}`)
                    }
                  })
                }}
              >
                下线
              </a>
            ) : (
              <a
                href="javascript:;"
                className="btn_online"
                onClick={() => {
                  // 上线
                  doOnlineWork(record.work_relation_id).then((res) => {
                    if (res.result === 'success') {
                      // 成功
                      this.init()
                      message.success('上线成功')
                    } else {
                      message.error(`上线失败:${res.message}`)
                    }
                  })
                }}
              >
                上线
              </a>
            )
          }
          <Popover
            placement="bottomRight" 
            content={(
              <div>
                <a href={getJump(`/frame/${window.bizCourseId}/work/edit/${record.work_id}`)}>
                  <Icon type="edit" />&nbsp;&nbsp;编辑
                </a>
                <Divider style={{
                  margin: '10px 0 10px 0',
                  padding: 0
                }}
                />
                <a
                  href="javascript:;"
                  onClick={() => {
                    // 上线
                    doDeleteWork(record.work_id).then((res) => {
                      if (res.message === '成功') {
                        message.success('删除成功')
                        this.init()
                      } else {
                        message.error('删除失败')
                      }
                    })
                  }}
                >
                  <Icon type="delete" />&nbsp;&nbsp;删除
                </a>
              </div>
            )}
            overlayClassName="custom-popover"
            overlayStyle={{ zIndex: 9999 }}
          >
            <Icon
              className="btn_more"
              type="ellipsis"
            />
          </Popover>
        </span>
      )
    }
  ]

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { workList } = this.state;
    if (oldIndex !== newIndex) {
      this.setState({
        loading: true
      })
      const newData = arrayMoveImmutable([].concat(workList), oldIndex, newIndex).filter(el => !!el);
      // console.log('Sorted items: ', newData);
      // 更新
      const updateReqs = []
      newData.forEach((_data, idx) => {
        if (_data.index !== idx) {
          // 排序变更了
          // 比如原来排第5的，现在变成了第3，那么他的order_number应该变成原第3位的order_number
          updateReqs.push({
            work_relation_id: _data.work_relation_id,
            order_number: workList[idx].order_number
          })
        }
      })
      updateWorkOrder(updateReqs).then((res) => {
        if (res.result === 'success') {
          message.success('排序成功')
          // 重置index 和 order_number
          newData.forEach((_data, idx) => {
            _data.index = idx
            const newOrderData = updateReqs.find((_ur) => _ur.work_relation_id === _data.work_relation_id)
            // 如果找到 说明有进行拖动变更
            if (newOrderData?.order_number) {
              _data.order_number = newOrderData?.order_number
            }
          })
          this.setState({
            loading: false,
            workList: newData
          })
        } else {
          this.setState({
            loading: false
          })
        }
      })
    }
  };

  DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={this.onSortEnd}
      {...props}
    />
  );

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { workList } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = workList.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  render() {
    const { current, limit, total } = this.state
    return (
      <div className="_teacher_work">
        <Button
          type="primary"
          onClick={() => {
            jumpTo(`/frame/${window.bizCourseId}/work/add`)
          }}
        >
          添加作业
        </Button>
        <p>
          &nbsp;
        </p>
        <Table
          pagination={false}
          columns={this.columns}
          dataSource={this.state.workList}
          loading={this.state.loading}
          bordered
          rowKey="index"
          components={{
            body: {
              wrapper: this.DraggableContainer,
              row: this.DraggableBodyRow
            }
          }}
        />
        <p>
          &nbsp;
        </p>
        <Pagination showQuickJumper current={current} pageSize={limit} total={total} onChange={this.onChange} />
      </div>
    )
  }
}

export default Work
