/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Gallery, Video, Icon } from 'fish'
import Attachment from './attachment'

import './index.less'

function Attachments({
  values
}) {
  const [isGallery, setIsGallery] = useState(false)
  const [startIndex, setStartIndex] = useState(0)
  const [videoVisible, setVideoVisible] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [images, setImages] = useState([])

  const [videoOptions, setVideoOptions] = useState({
    fluid: false,
    controls: true,
    autoplay: true,
    width: 800,
    height: 450,
    techOrder: ['html5', 'flash']
  })

  // 标注图片信息
  const getAttachmentsInfo = (_attachments) => {
    let imageIndex = -1
    return _attachments?.map((attachment) => {
      if (attachment.media_type === 0) {
        imageIndex += 1
        return {
          ...attachment,
          index: imageIndex
        }
      }
      return attachment
    })
  }

  // 初始数据填充
  useEffect(() => {
    // 图片查看器使用属性
    const _attachments = getAttachmentsInfo(values)
    if (_attachments?.length > 0) {
      setAttachments([..._attachments])
    } else {
      setAttachments([])
    }
  }, [values])

  // 过滤图片数据
  useEffect(() => {
    const _images = attachments?.filter((attachment) => attachment.media_type === 0).map((attachment) => {
      let ext_info = {}
      try {
        ext_info = JSON.parse(attachment.ext_info)
      } catch (ex) {
        //
      }
      return {
        original: attachment.url,
        thumbnail: attachment.url,
        description: ext_info.file_name
      }
    })
    if (_images?.length > 0) {
      setImages(_images)
    }
  }, [attachments])

  // 查看大图
  const openGallery = (index) => {
    setIsGallery(true)
    setStartIndex(index)
  }

  // 关闭大图
  const closeGallery = () => {
    setIsGallery(false)
  }

  // 查看视频
  const openVideoModal = (data) => {
    setVideoVisible(true)
    setVideoOptions({
      ...videoOptions,
      poster: data.screenshot,
      sources: [
        {
          // 视频源
          src: data.url || data.path,
          type: 'video/mp4' // 类型
        }
      ]
    })
  }

  // 删除附件
  const onDelete = (_file) => {
    setAttachments(attachments.filter((_att) => _att.attachment_id !== _file.attachment_id))
  }

  // 显示附件 更新图 是否编辑
  return (
    <>
      <div className="attachments" id="workAttachmentsList">
        <Gallery
          images={images}
          infinite={false}
          startIndex={startIndex}
          autoPlay={false}
          showToolbar
          showThumbnail
          onClose={closeGallery}
          visible={isGallery}
          getContainer={() => document.getElementById('workAttachmentsList')}
        />
        <div className="attachmentsImage">
          {attachments?.filter((attachment) => attachment.media_type === 0)?.map((attachment) => (
            <Attachment
              edit={false}
              key={attachment?.attachment_id}
              data={attachment}
              onImagePlay={openGallery}
              onVideoPlay={openVideoModal}
              onDelete={onDelete}
            />
          ))}
        </div>
        {attachments?.filter((attachment) => attachment.media_type !== 0)?.map((attachment) => (
          <Attachment
            edit={false}
            key={attachment?.attachment_id}
            data={attachment}
            onImagePlay={openGallery}
            onVideoPlay={openVideoModal}
            onDelete={onDelete}
          />
        ))}
      </div>
      {/* 视频弹窗 */}
      <div
        className="model"
        style={{
          display: videoVisible ? 'block' : 'none'
        }}
      >
        <div
          className="video"
        >
          {videoVisible && <Video className="fish-ndrVideo vjs-big-play-centered" options={videoOptions} />}
          <div
            className="close"
            onClick={() => {
              setVideoOptions({
                ...videoOptions,
                sources: []
              })
              setVideoVisible(!videoVisible)
            }}
            onKeyPress={()=>{}}
          >
            <Icon type="close-circle" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Attachments
