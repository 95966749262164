import { handleActions } from 'redux-actions';
import * as T from './actionTypes';

const OnlineExams = handleActions(
  {
    [T.GET_ONLINEEXAM_MANAGE_INFO]: (state, action) => {
      return {
        ...action.payload
      };
    }
  },
  null
);

const OfflineExams = handleActions(
  {
    [T.GET_OFFLINEEXAM_MANAGE_INFO]: (state, action) => {
      return {
        ...action.payload
      };
    }
  },
  null
);

const CourseDetail = handleActions(
  {
    [T.GET_COURSE_DETAIL]: (state, action) => {
      return {
        ...action.payload
      };
    }
  },
  null
);

export { CourseDetail, OfflineExams, OnlineExams };
