import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Icon } from 'fish';
import { isWebfront } from 'modules/common/queryString';
import getReturnUrl from 'utils/getReturnUrl'

const TITLE_I18N_PREFIX = 'key:';

export const StandardLayout = (options) => {
  let title = (options && options.title) || '';
  const returnUrl = (options && options.returnUrl) 
    || getReturnUrl()
    || 'javascript:;';

  return (WrappedComponent) => {
    @injectIntl
    class StandardLayoutComponent extends React.Component {
      constructor(props) {
        super(props);

        if (title.indexOf(TITLE_I18N_PREFIX) === 0) {
          let i18n = title.substring(TITLE_I18N_PREFIX.length);
          title = props.intl.formatMessage({ id: i18n });
        }
        document.title = title;
      }

      renderHeader = () => {
        if (isWebfront) return null;
        return (
          <div className="edui-layout__body-header">
            <div className="back" style={{ visibility: window.parent ? 'hidden' : 'visible' }}>
              <a href={returnUrl}>
                <Icon type="left" />
                <FormattedMessage id="menu.back" defaultMessage="返回" />
              </a>
            </div>
            <div className="title">{title}</div>
          </div>
        );
      };

      render() {
        return (
          <div className="edui-layout">
            <div className="edui-layout__container">
              <div className="edui-layout__sidecontainer edui-layout__sidecontainer--nomenu">
                <div className="edui-layout__container">
                  <div className="edui-layout__panel edui-layout--position">
                    <div className="edui-layout__body">
                      {this.renderHeader()}
                      <WrappedComponent {...this.props} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    StandardLayoutComponent.propTypes = {
      intl: PropTypes.object.isRequired
    };

    return StandardLayoutComponent;
  };
};
