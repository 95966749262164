/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { message } from 'fish'
import { stringify } from 'query-string'
import { getUrlQuery } from 'utils/url'
import { uc, checkLoginState } from 'utils/auth/func'
import UserContext from './UserContext'

function Login({
  children
}) {
  const [userInfo, setUserInfo] = useState()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const query = getUrlQuery()
    const {
      ucKey, __mac: mac, auth, uckey
    } = query
    const curUcKey = ucKey || uckey || mac || auth
    async function checkAndSaveLoginState() {
      const user = await checkLoginState()
      if (user) {
        setUserInfo(user)
      } else {
        // eslint-disable-next-line no-console
        console.error('uc未登录或登陆失败')
      }
      setLoaded(true)
    }
    if (curUcKey) {
      let curUrl = '/'
      // 剔除 mac或auth参数
      if (mac || auth) {
        const url = window.location.href
        let macIndex = url.lastIndexOf('&')
        macIndex = macIndex === -1 ? url.lastIndexOf('?') : macIndex
        curUrl = url.substring(0, macIndex)
      }
      uc.loginByUCKey({
        uckey: curUcKey,
        url: curUrl
      })
        .then(() => {
          delete query.uckey
          delete query.auth
          delete query.__mac
          const newParams = stringify(query)
          window.location.replace(`${window.location.href.split('?')[0]}${newParams ? `?${newParams}` : ''}`)
          checkAndSaveLoginState()
        })
        .catch((error) => {
          message.error(`登录失败，请重试：${error.message}`)
        })
    } else {
      checkAndSaveLoginState()
    }
  }, [])

  return (
    <UserContext.Provider value={{
      userInfo,
      setUserInfo
    }}
    >
      {loaded ? children : null}
    </UserContext.Provider>
  )
}

export default Login
