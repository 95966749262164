import { AdminMenu } from 'modules/layouts/adminMenu';
import { StandardLayout } from 'modules/layouts/standardLayout';
import { getQueryStringByName } from 'modules/common/urlHelper';

export const SmartLayout = (options) => {
  const title = (options && options.title) || '';
  const noMenu = (options && options.noMenu) || (getQueryStringByName('nomenu') && getQueryStringByName('nomenu') === 'true' ? true : false);

  if (!noMenu && options.key) {
    let amo = { key: options.key };
    if (options.items) amo.items = options.items;

    return AdminMenu(amo);
  } else {
    return StandardLayout({ title });
  }
};
