/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Avatar } from 'fish'
import { uc } from 'utils/auth/func'
import Attachments from './Attachments'
import './index.less'

export default function WorkResultDetail({
  workData,
  workResultData
}) {
  const [showMore, setShowMore] = useState(false)
  return (
    <div className="work_result_detail">
      <div className="head">
        <div className="head_title">
          {workResultData?.title}
        </div>
        <div className="head_detail">
          <Avatar
            src={uc.getAvatarURL({
              userId: workResultData?.user_id
            })}
          />
          <span className="head_name">
            {workResultData?.name}
          </span>
          <span className="head_time">
            {
              dayjs(workResultData?.submit_time).format('YYYY/MM/DD')
            }
          </span>
        </div>
      </div>
      <div className="work">
        <div className="work_tag">作业要求</div>
        <div className="work_title">{workData?.title}</div>
        {
          !showMore && (
            <>
              <div className="work_detail">{workData?.work_detail}</div>
              <div
                className="work_more"
                onClick={
                  () => {
                    setShowMore(true)
                  }
                }
              >
                查看全部
              </div>
            </>
          )
        }
        {
          showMore && (
            <>
              <div className="work_all">
                <div>
                  {workData?.work_detail}
                </div>
                <Attachments values={workData?.attachments} />
              </div>
              <div
                className="work_more"
                onClick={
                  () => {
                    setShowMore(false)
                  }
                }
              >
                隐藏
              </div>
            </>
          )
        }
      </div>
      <div className="result">
        <div
          className="result_desc"
          dangerouslySetInnerHTML={{
            __html: workResultData?.content || workResultData?.answer
          }}
        />
        <Attachments values={workResultData?.attachments} />
      </div>
    </div>
  )
}
