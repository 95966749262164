/**
 * @module eventemitter decorator
 * @author vnot <weinotme@gmail.com>
 */

import EventEmitter from 'eventemitter2';
// import EventEmitter from 'eventemitter3';

/**
 * add EventEmitter capacity to the given class
 * @param clazz
 */
export default function eventable(clazz) {
  // clazz.prototype = { ...clazz.prototype, ...EventEmitter.prototype };
  Object.assign(clazz.prototype, EventEmitter.prototype);
}
