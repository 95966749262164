import { find } from 'lodash';

export function checkExtendReadingEnable(bizCourseTemplate) {
  if(bizCourseTemplate && bizCourseTemplate.custom_form) {
    const extendReadingSetting = find(
      bizCourseTemplate.custom_form,
      (t) => t.field === 'extend_reading'
    )
    return extendReadingSetting && extendReadingSetting?.type !== 'hide'
  }
  return false
}

export function checkExtendMaxPeriodEnable(bizCourseTemplate) {
  return bizCourseTemplate && bizCourseTemplate.template_name === "t_course"
}

export function downloadFile(link) {
  let element = document.createElement('a');
  element.setAttribute('href', link);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}