import * as $dp from 'dataProvider';

// 查询课程关联作业列表
export const getWorkList = (query) => {
  return $dp.workBusiness.getWorkList
    .replace('course_id', window.bizCourseId)
    .query(query)
    .get()
}

// 作业排序
export const updateWorkOrder = (data) => {
  return $dp.workBusiness.updateOrder
    .replace('course_id', window.bizCourseId)
    .send(data)
    .post()
}

// 上线作业
export const doOnlineWork = (work_relation_id) => {
  return $dp.workBusiness.online
    .replace('work_relation_id', work_relation_id)
    .put()
}

// 下线作业
export const doOfflineWork = (work_relation_id) => {
  return $dp.workBusiness.offline
    .replace('work_relation_id', work_relation_id)
    .put()
}

// 查询作业详情
export const getWorkDetail = (work_id) => {
  return $dp.workBusiness.detail
    .replace('work_id', work_id)
    .get()
}

// 删除作业
export const doDeleteWork = (work_id) => {
  return $dp.workBusiness.delete
    .replace('work_id', work_id)
    .delete()
}

// 添加作业
export const createWork = (data) => {
  return $dp.workBusiness.create
    .send(data)
    .post()
}

// 添加作业与课程关系
export const createRelation = (data) => {
  return $dp.workBusiness.createRelation
    .send(data)
    .post()
}

// 编辑作业
export const updateWork = (work_id, data) => {
  return $dp.workBusiness.update
    .replace('work_id', work_id)
    .send(data)
    .put()
}

// 查询作业成果
export const getWorkResultList = (data = {}, query) => {
  return $dp.workBusiness.getWorkResultList
  .replace('course_id', window.bizCourseId)
  .query(query)
  .send(data)
  .post()
}

// 查询作业成果详情
export const getWorkResultDetail = (workResultId) => {
  return $dp.workBusiness.getWorkResultDetail
  .replace('work_result_id', workResultId)
  .get()
}

// 取消推荐优秀作业成果
export const closeRecommend = (work_result_id) => {
  return $dp.workBusiness.closeRecommend
  .replace('work_result_id', work_result_id)
  .put()
}

// 推荐优秀作业成果
export const openRecommend = (work_result_id) => {
  return $dp.workBusiness.openRecommend
  .replace('work_result_id', work_result_id)
  .put()
}
