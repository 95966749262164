function getQueryStringByName(name, search) {
  search = search || window.location.search;
  let result = search.match(new RegExp(`[?&]${name}=([^&]+)`, 'i'));

  if (result == null || result.length < 1) {
    return '';
  }

  return result[1];
}

export { getQueryStringByName };
