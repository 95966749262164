import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import { ConfigProvider } from 'fish';
import locales from './locale';
import localesFish from './fish';
import LoadingComponent from '../modules/common/loading';

if (!global.Intl) {
  global.Intl = require('intl');
  require('./intl');
}

const defaultLang = 'zh-CN';

export default class Intl extends React.Component {
  constructor(props) {
    super(props);

    // 语言优先级
    //   1. 从 window.locale 中取
    //   2. 从 url query string 中取
    //   3. 从 this.props.locale 中拿
    //   4. 从 浏览器 navigator.languages 中拿
    //   5. 从 浏览器 UI 语言中拿
    let locale = window.locale || window.eduBasicLib.getLang() || 'zh-CN';
    if (!locale) {
      let languagesFirstItem = global.navigator.languages && global.navigator.languages[0];
      let language = global.navigator.language || languagesFirstItem || global.navigator.userLanguage || defaultLang;
      locale = props.locale || language;
    }

    this.state = {
      localesLocal: locales[locale],
      locale: locale
    };
  }

  getQueryString = (name) => {
    let reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    let r = global.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  };

  render() {
    let { locale, localesLocal } = this.state;
    let zoneNow = moment().utcOffset();
    if (!localesLocal) {
      return <LoadingComponent />;
    }
    if (!localesFish[locale]) {
      localesFish[locale] = {};
    }
    return (
      <IntlProvider locale={locale} key={locale} messages={localesLocal}>
        <ConfigProvider
          locale={{
            ...(localesFish[locale].default || localesFish[locale]), 
            ...{ timezoneOffset: zoneNow }
          }}
        >
          {this.props.children}
        </ConfigProvider>
      </IntlProvider>
    );
  }
}

Intl.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.object
};

Intl.defaultProps = {
  locale: 'zh-CN',
  children: null
};
