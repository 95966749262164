import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Radio, Button, Card } from 'fish';
import message from '@sdp.nd/edu-message';
import { AdminMenu } from 'modules/layouts/adminMenu';
import PeriodInput from './periodInput';
import * as actions from './actions';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

@AdminMenu({ key: 'biz-course-period' })
@injectIntl
class Period extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saving: false };
  }

  componentDidMount() {
    const { getCourseDetail } = this.props;
    getCourseDetail({ businessCourseId: window.bizCourseId });
  }

  onSubmit = (e) => {
    const { detail } = this.props;
    this.setState({ saving: true });

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let options = {
          businessCourseId: window.bizCourseId,
          data: {
            name: detail.name,
            front_cover_object_id: detail.front_cover_object_id,
            introduce_video_id: detail.introduce_video_id,
            summary: detail.summary,
            introduction: detail.introduction,
            user_suit: detail.user_suit,
            status: detail.status,
            qr_code_url: detail.qr_code_url,
            user_access_limit: detail.user_access_limit,
            study_time_limit_type: detail.study_time_limit_type,
            study_start_time: detail.study_start_time,
            study_end_time: detail.study_end_time,
            study_days: detail.study_days,
            adjust_period: values.adjust_period === -1 ? values.adjust_period : values.adjust_period * 60 * 60,
            info_display_type: parseInt(values.info_display_type, 10)
          }
        };
        this.props.updateDetail(options).then(() => {
          const { intl } = this.props;
          this.setState({ saving: false });

          message.success(intl.formatMessage({ id: 'period.updateSuccess', defaultMessage: '更新成功' }));
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { detail } = this.props;

    if (!detail) return null;

    // https://pms.101.com/index.php?m=bug&f=view&bugID=643664
    // 先不显示学时显示设置
    return (
      <Card bordered={false}>
        <Form labelCol={formItemLayout.labelCol} wrapperCol={formItemLayout.wrapperCol} onSubmit={this.onSubmit}>
          <Form.Item label={<FormattedMessage id="period.displayType.period" defaultMessage="学时" />}>{getFieldDecorator('adjust_period')(<PeriodInput />)}</Form.Item>
          <Form.Item style={{ display: 'none' }} label={<FormattedMessage id="period.label.displayType" defaultMessage="前端信息展示方式" />}>
            {getFieldDecorator('info_display_type')(
              <Radio.Group buttonStyle="solid">
                <Radio value="1">
                  <FormattedMessage id="period.displayType.period" defaultMessage="学时" />
                </Radio>
                <Radio value="2">
                  <FormattedMessage id="period.displayType.percent" defaultMessage="进度百分数" />
                </Radio>
                <Radio value="3">
                  <FormattedMessage id="period.displayType.contentNum" defaultMessage="内容数量" />
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" shape="round" htmlType="submit" disabled={this.state.saving}>
              <FormattedMessage id="period.save" defaultMessage="保存" />
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

Period.propTypes = {
  getCourseDetail: PropTypes.object.isRequired,
  updateDetail: PropTypes.object.isRequired,
  form: PropTypes.any.isRequired,
  detail: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
};

export default connect(
  (state) => {
    return {
      detail: state.CourseDetail ? state.CourseDetail : null
    };
  },
  {
    ...actions
  }
)(
  Form.create({
    mapPropsToFields: (props) => {
      const detail = props.detail || undefined;

      let formset = {
        adjust_period: Form.createFormField({ value: detail ? detail.config.adjust_period / 60 / 60 : -1 }),
        front_cover_object_id: Form.createFormField({ value: detail ? detail.front_cover_object_id : null }),
        info_display_type: Form.createFormField({ value: detail ? detail.config.info_display_type.toString() : '1' }),
        introduce_video_id: Form.createFormField({ value: detail ? detail.introduce_video_id : null }),
        introduction: Form.createFormField({ value: detail ? detail.introduction : '' }),
        name: Form.createFormField({ value: detail ? detail.name : '' }),
        qr_code_url: Form.createFormField({ value: detail ? detail.qr_code_url : null }),
        status: Form.createFormField({ value: detail ? detail.status : -1 }),
        summary: Form.createFormField({ value: detail ? detail.summary : null }),
        user_suit: Form.createFormField({ value: detail ? detail.user_suit : null })
      };

      return formset;
    }
  })(Period)
);
