import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Clipboard, Row, Col, Button, Input, Form, Qrcode, Divider, Card } from 'fish';
import message from '@sdp.nd/edu-message';
import { SmartLayout } from 'modules/layouts/smartLayout';

@SmartLayout({ key: 'biz-course-qrcode', title: 'key:menu.course.info.qrcode' })
@injectIntl
class QRCode extends PureComponent {
  onCopy = (text, result) => {
    const { intl } = this.props;
    if (result) {
      message.success(intl.formatMessage({ id: 'qrcode.copy_success', defaultMessage: '复制成功' }));
    } else {
      message.success(intl.formatMessage({ id: 'qrcode.copy_error', defaultMessage: '复制失败，请手动复制' }));
    }
  };

  render() {
    // const webLink = `${window.gaeaConfig.bizCourse.portal}/portal/course/detail?course_id=${window.bizCourseId}`;
    // const h5Link = `${window.gaeaConfig.bizCourse.portal}/portal/m/course/detail?course_id=${window.bizCourseId}`;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Card bordered={false}>
        <Form labelCol={formItemLayout.labelCol} wrapperCol={formItemLayout.wrapperCol}>
          <Form.Item label={<FormattedMessage id="qrcode.h5_link" defaultMessage="H5地址" />}>
            <Row>
              <Col span={10}>
                <Input value={window.h5Url} disabled />
              </Col>
              <Col>
                <Clipboard text={window.h5Url} onCopy={(text, result) => this.onCopy(text, result)}>
                  <Button type="primary">
                    <FormattedMessage id="qrcode.copy_link" defaultMessage="复制链接" />
                  </Button>
                </Clipboard>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label={<FormattedMessage id="qrcode.qrcode" defaultMessage="二维码" />}>
            <Row>
              <Col>
                <Qrcode text={window.h5Url} size="large" />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label={<FormattedMessage id="qrcode.web_link" defaultMessage="WEB链接" />}>
            <Row>
              <Col span={10}>
                <Input value={window.webUrl} disabled />
              </Col>
              <Col>
                <Clipboard text={window.webUrl} onCopy={(text, result) => this.onCopy(text, result)}>
                  <Button type="primary">
                    <FormattedMessage id="qrcode.copy_link" defaultMessage="复制链接" />
                  </Button>
                </Clipboard>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label={<FormattedMessage id="qrcode.qrcode" defaultMessage="二维码" />}>
            <Row gutter={8}>
              <Col>
                <Qrcode text={window.webUrl} size="large" />
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <div>
          <Divider />
          <div>
            <h3>
              <FormattedMessage id="qrcode.tip" defaultMessage="说明" />
            </h3>
            <p>
              <FormattedMessage id="qrcode.tip.mobileScan" defaultMessage="可以用手机99u扫码直接打开地址" />
            </p>
          </div>
        </div>
      </Card>
    );
  }
}

QRCode.propTypes = {
  intl: PropTypes.object.isRequired
};

export default QRCode;
