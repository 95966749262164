import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Radio, InputNumber } from 'fish';


@injectIntl
class CourseDatePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      maxPeriod: props.value,
      isLimit: props.value >= 0
    };
  }

  triggerChange = () => {
    // 限制学时，允许限制成0学时
    let newMaxPeriod= -1;
    if(this.state.isLimit){
      newMaxPeriod = this.state.maxPeriod < 0 ? 0 : this.state.maxPeriod;
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(newMaxPeriod);
    }
  };

  onMaxPeriodChange = (value) => {
    this.setState(
      {
        isLimit: value >= 0,
        maxPeriod: value
      },
      () => {
        this.triggerChange({ ...this.state });
      }
    );
  };

  onRadioChange = (e) => {
    this.setState({ isLimit: e.target.value === 'true' }, () => {
      this.triggerChange({ ...this.state });
    });
  };

  limitNumber = (value) => {
    if (typeof value === 'string') {
      let dotIndex = value.indexOf('.')
      return value.substring(0, dotIndex > -1 && dotIndex + 1 !== value.length ? dotIndex + 2 : value.length);
    } else {
      return value;
    }
  };

  render() {
    const { isLimit, maxPeriod } = this.state;
    const { disabled } = this.props
    return (
      <div>
        <Radio.Group onChange={this.onRadioChange} value={isLimit.toString()} disabled={disabled}>
          <Radio value="false"><FormattedMessage id="maxPeriod.notLimit" defaultMessage="不限，为课程学时" /></Radio>
          <Radio value="true">
            <FormattedMessage id="maxPeriod.limit" defaultMessage="限制" />
            <InputNumber disabled={disabled} formatter={this.limitNumber} parser={this.limitNumber} onChange={this.onMaxPeriodChange} value={maxPeriod} min={0} max={9999} style={{ width: 120, margin: '0 10px' }} />
            <FormattedMessage id="maxPeriod.period" defaultMessage="学时" />
          </Radio>
        </Radio.Group>
      </div>
    );
  }
}

CourseDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default CourseDatePicker;
