/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
import React from 'react'
import {
  Form,
  Input,
  Button,
  Radio,
  message
} from 'fish'
import { connect } from 'react-redux';
import * as actions from './actions';
import { createWork, createRelation, updateWork } from '../actions'
import WorkUpload from './upload';

const { TextArea } = Input

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

class BaseWork extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      attachments: []
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        span: 18,
        offset: 4
      }
    }

    const lengthRuleFun = (character, max = 255) => {
      if (character.charCodeAt(0) <= max) {
        return 0.5;
      }
      return 1;
    }

    const { detail } = this.props

    return (
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) return
            // 附件数组化
            // const attachments = []
            // if (values.attachments) {
            //   Object.keys(values.attachments).forEach((_key) => {
            //     attachments.push(values.attachments[_key])
            //   })
            // }
            // values.attachments = window._tmp_work_file_action ? attachments : detail?.attachments
            values.attachments = window._tmp_work_file_action ? this.state.attachments : detail?.attachments
            if (detail) {
              // 修改
              updateWork(detail.work_id, values).then((res) => {
                if (res.work_id) {
                  message.success('修改作业成功')
                  window.history.back()
                } else {
                  message.error('修改作业失败')
                }
              })
            } else {
              createWork(values).then(async ({
                work_id,
                title
              }) => {
                // 课程和作业关联
                const res = await createRelation({
                  course_id: window.bizCourseId,
                  work_id,
                  title
                })
                if (res.work_id) {
                  message.success('添加作业成功')
                  window.history.back()
                } else {
                  message.error('添加作业失败')
                }
              })
            }
            if (!err) {
              // console.log('收到表单值：', values);
            }
          })
        }}
      >
        <Form.Item label="标题">
          {getFieldDecorator('title', {
            rules: [{ required: true, message: '请输入标题!', whitespace: true }],
            initialValue: detail?.title
          })(<Input
            maxLength={30}
            placeholder="请输入标题"
            lengthRule={character => {
              return lengthRuleFun(character, 30)
            }}
          />)}
        </Form.Item>
        <Form.Item label="作业活动完成条件">
          {getFieldDecorator('complete_mode', { initialValue: detail?.complete_mode || 1 })(
            <Radio.Group>
              <Radio value={1}>提交作业成果</Radio>
              <Radio value={2}>查阅即可</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item label="说明">
          {getFieldDecorator('work_desc', { initialValue: detail?.work_desc })(
            <TextArea
              style={{ height: '150px' }}
              placeholder="请输入说明"
              maxLength={255}
              // onChange={e => {
              //   this.handleChange(e);
              // }}
              // onBlur={e => {
              //   this.handleChange(e);
              // }}
              showCounter
              lengthRule={character => {
                return lengthRuleFun(character)
              }}
            />
          )}
        </Form.Item>
        <Form.Item label="内容详情">
          {getFieldDecorator('detail', {
            rules: [{ required: true, message: '请输入内容详情!', whitespace: true }],
            initialValue: detail?.work_detail
          })(
            <TextArea
              style={{ height: '300px' }}
              placeholder="请输入内容"
              maxLength={10000}
              // onChange={e => {
              //   this.handleChange(e);
              // }}
              // onBlur={e => {
              //   this.handleChange(e);
              // }}
              showCounter
              lengthRule={character => {
                return lengthRuleFun(character, 10000)
              }}
            />
          )}
        </Form.Item>
        {
          detail?.attachments ? (
            <Form.Item label="附件">
              <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                {getFieldDecorator('attachments')(
                  <WorkUpload
                    getUploadTokens={this.props.getUploadTokens}
                    attachments={detail?.attachments}
                    onChange={(atts) => {
                      this.setState({
                        attachments: atts
                      })
                    }}
                  />)}
              </Form.Item>
            </Form.Item>
          ) : (
            <Form.Item label="附件">
              <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                {getFieldDecorator('attachments')(
                  <WorkUpload
                    getUploadTokens={this.props.getUploadTokens}
                    onChange={(atts) => {
                      this.setState({
                        attachments: atts
                      })
                    }}
                  />)}
              </Form.Item>
            </Form.Item>
          )
        }
        <Form.Item {...tailFormItemLayout}>
          <Button onClick={() => {
            window.history.back()
          }}
          >
            取消
          </Button>
          &nbsp;&nbsp;
          <Button type="primary" htmlType="submit">
            保存
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default connect(
  (state) => {
    return {
      ...state
    };
  }, {
  ...actions
})(
  Form.create({ name: 'baseWorkForm' })(BaseWork)
)