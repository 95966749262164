/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Upload, Spin, Icon } from 'fish';
import message from '@sdp.nd/edu-message';

import './upload.less';
import { cdnPrefix, cdnReadHost } from 'cfg/env'

// const DEFAULT_COVER_URL = '//s11.e.99.com/auxo/common/images/default_video_cover.png';

@injectIntl
class WorkUpload extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      percent: 0,
      uploadInfo: {},
      files: props.attachments ? props.attachments : [],
      timestamp: null
    };
  }

  getUploadProps = () => {
    const { intl } = this.props;

    const VIDEO_EXTS = '.mp4,.avi,.mkv,.wmv,.mpg,.f4v,.flv,.rmvb,.asf,.mov,.mpeg,.rm,.3gp,.ts'
    const AUDIO_EXTS = '.wav,.midi,.mp3,.vqf,.ogg,.flac,.ape,.alac,.wv,.aac,.opus,.voc'
    const DOC_EXTS = '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.wps'
    const IMAGE_EXTS = '.jpg,.png,.jpeg,.gif'
    const DefaultAccept = `${VIDEO_EXTS},${AUDIO_EXTS},${DOC_EXTS},${IMAGE_EXTS}`

    const props = {
      name: 'file',
      fileSize: 1024 * 1024 * 1024,
      // fileType: '.mp4',
      accept: DefaultAccept,
      multiple: false,
      showUploadList: false,
      data: this.getData,
      action: this.getAction,
      beforeUpload: (file) => {
        this.setState({ percent: 0 });
        if (this.state.files?.length >= 8) {
          message.error('最多只能上传8个附件')
          return false
        }
        const fileName = file.name.toLowerCase()
        if (!DefaultAccept.split(',').some((ext) => fileName.endsWith(ext))) {
          message.error('上传失败，存在不支持的文件类型')
          return false
        }
    
        if (file.size === 0) {
          message.error('上传失败，不能上传0字节的文件')
          return false
        }
    
        if (file.size > 10 * 1024 * 1024) {
          message.error('上传失败，不能上传超过10M的文件')
          return false
        }
      },
      onError: this.handleError,
      onChange: (info) => {
        const { status } = info.file;
        if (status === 'uploading') {
          this.setState({ uploading: true, percent: info.file.percent || 0 });
        }
        if (status === 'done') {
          const { files } = this.state
          let newFiles = [...files, info.file]
          if (!this.state.timestamp) {
            // 第一次上传
            newFiles = [...(this.props.attachments || []), ...newFiles]
          }
          this.setState(
            {
              uploading: false,
              percent: info.percent || 0,
              files: newFiles,
              timestamp: new Date().getTime()
            },
            () => {
              const filesRes = newFiles.map((_file) => {
                if (_file.ext_info) {
                  // 已经上传的文件
                  return _file
                }
                const extInfo = {
                  file_name: _file.name,
                  size: _file.response?.inode?.size,
                  md5: _file.response?.inode?.md5,
                  duration: _file.response?.inode?.duration ? _file.response?.inode?.duration / 1000 : 0
                }
                
                let mediaType = 0
                const extType = _file.name.substr(_file.name.lastIndexOf('.'))
                if (IMAGE_EXTS.indexOf(extType) > -1) {
                  // 图片
                  mediaType = 0
                } else if (AUDIO_EXTS.indexOf(extType) > -1) {
                  // 音频
                  mediaType = 1
                } else if (VIDEO_EXTS.indexOf(extType) > -1) {
                  // 视频
                  mediaType = 2
                } else {
                  mediaType = 3
                }
                // let mediaType = 0
                // if (_file.response?.inode?.mime.indexOf('image') > -1) {
                //   mediaType = 0
                // } else if (_file.response?.inode?.mime.indexOf('audio') > -1) {
                //   mediaType = 1
                // } else if (_file.response?.inode?.mime.indexOf('video') > -1) {
                //   mediaType = 2
                // } else {
                //   mediaType = 3
                // }
                return {
                  attachment_id: _file.response.dentry_id,
                  media_type: mediaType,
                  url: `https:${cdnReadHost}${cdnPrefix}${_file.response.path}?name=${_file.name}`,
                  ext_info: JSON.stringify(extInfo)
                }
              })
              this.triggerChange(filesRes);
              setTimeout(() => {
                this.setState({
                  files: filesRes,
                  timestamp: new Date().getTime()
                })
              }, 300)
            }
          );
        } else if (status === 'error') {
          this.setState({ uploading: false, percent: info.percent || 0 });
          message.success(intl.formatMessage({ id: 'upload.fail', defaultMessage: '上传失败！' }));
        }
      }
    };

    return props;
  };

  getData = (file) => {
    return {
      name: file.name,
      scope: 1,
      path: this.state.uploadInfo.path
    };
  };

  getAction = () => {
    return new Promise((resolve) => {
      this.props.getUploadTokens().then(res => {
        let uploadInfo = res.payload ? res.payload : res
        this.setState({ uploadInfo }, () => {
          let uploadUrl = `//${uploadInfo.server_url
            }/v0.1/upload?token=${encodeURIComponent(uploadInfo.token)
            }&policy=${uploadInfo.policy}&date=${encodeURIComponent(uploadInfo.date_time)}`;
          resolve(uploadUrl);
        });
      });
    });
  };

  getUploadPercent = () => {
    return `${this.state.percent.toFixed(0)}%`;
  };

  handleError = (file, code) => {
    const { intl } = this.props;
    if (code === -130) {
      message.success(intl.formatMessage({ id: 'introVideo.upload.fileType', defaultMessage: '只能上传mp4文件！' }));
    }
    if (code === -110) {
      message.success(intl.formatMessage({ id: 'introVideo.upload.fileSize', defaultMessage: '图片文件不能大于500MB！' }));
    }
  };

  triggerChange = (changedValue) => {
    window._tmp_work_file_action = new Date().getTime()
    const { onChange } = this.props;
    if (onChange) {
      onChange([
        ...changedValue
      ]);
    }
  };

  render() {
    const uploadProps = this.getUploadProps();
    const { uploading, files } = this.state;
    const tip = this.getUploadPercent();
    const { disabled } = this.props

    return (
      <div>
        <Upload.Dragger
          disabled={uploading || disabled}
          name="files"
          {...uploadProps}
        >
          <div style={{
            color: '#1E62EC'
          }}
          >
            <Icon type="plus-circle" />
            &nbsp;&nbsp;
            点击或拖拽上传图片/视频/音频/文档
            <Spin spinning={uploading} tip={tip} />
          </div>
        </Upload.Dragger>
        <div className="upload_files_list">
          {
            (this.state.timestamp ? files : this.props.attachments)?.map((_file) => {
              return (
                <div className="upload_files_list_item">
                  <div>
                    {
                      _file.name || (_file?.ext_info && JSON.parse(_file.ext_info)?.file_name)
                    }
                  </div>
                  <Icon
                    type="delete"
                    onClick={() => {
                      const t = this.state.timestamp
                      this.triggerChange((t ? files : this.props.attachments).filter((_file_tmp) => _file_tmp.attachment_id !== _file.attachment_id))
                      this.setState({
                        files: (t ? files : this.props.attachments).filter((_file_tmp) => _file_tmp.attachment_id !== _file.attachment_id),
                        timestamp: new Date().getTime()
                      })
                    }}
                  />
                </div>
              )
            })
          }
        </div>
      </div>
      // <React.Fragment>
      //   <div className="video__image">
      //     <Spin spinning={uploading} tip={tip}>
      //       {videoId && <video src={videoUrl} controls="controls" />}
      //       {!videoId && <img alt="video" src={videoUrl} />}
      //     </Spin>
      //   </div>
      //   <div className="video__action__btns">
      //     <Upload {...uploadProps}>
      //       <Button disabled={uploading || disabled} shape="round">
      //         <FormattedMessage id="introVideo.action.uploadVideo" defaultMessage="上传视频" />
      //       </Button>
      //     </Upload>
      //   </div>
      //   <div className="video__action__tip">
      //     <p>
      //       <FormattedMessage id="introVideo.tip.fileType" defaultMessage="* 文件大小不超过500M, 仅支持MP4格式" />
      //     </p>
      //   </div>
      // </React.Fragment>
    );
  }
}

WorkUpload.propTypes = {
  getUploadTokens: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  attachments: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default WorkUpload;
