import One, { ucPlugin, omsPlugin, requestPlugin } from '@sdp.nd/one';
import moment from 'moment';
import { isWebfront } from 'modules/common/queryString';
import { appId, env } from 'cfg/env';
import oneConfig from '../cfg/oneConfig';

// const env = window?.gaea_js_config?.env === 'test' || window?.gaea_js_config?.env === 'development' || window?.gaea_js_config?.env === 'pressure' ? 'preproduction' : window?.gaea_js_config.env;
const _env = env === 'test' || env === 'development' || env === 'pressure' ? 'preproduction' : env;
const originsConfig = oneConfig[_env];
const sdpAppId = window.eduBasicLib.getAppData()['sdp-app-id'] || appId;

function getUcOrigins() {
  let ucOrigins = '';
  if (originsConfig) {
    ucOrigins = {
      UC: originsConfig.UC,
      SSO: originsConfig.SSO
    };
  }
  return ucOrigins || undefined;
}

function getOmsOrigins() {
  let omsOrigins = '';
  if (originsConfig) {
    omsOrigins = {
      OMS: originsConfig.OMS,
      OMS_SEARCH: originsConfig.OMS_SEARCH
    };
  }
  return omsOrigins || undefined;
}

function getConfig() {
  let macConfig = window.G_CONFIG;
  macConfig = window.Nova.base64.decode(decodeURIComponent(macConfig));
  macConfig = JSON.parse(macConfig);
  return macConfig;
}

function getAuthInfo() {
  const _config = getConfig();
  const DIFF_KEY = `diff_${_config.cookie_mac_key}`;
  const diff = window.$.cookie(DIFF_KEY);
  let macInfo = window.$.cookie(_config.cookie_mac_key);
  macInfo = window.Nova.base64.decode(decodeURIComponent(macInfo));
  macInfo = JSON.parse(macInfo);
  macInfo.sys_time = _config.sys_time;
  macInfo.server_time = moment(_config.sys_time, 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ').valueOf();
  macInfo.diff = diff;
  return macInfo;
}

// 初始化one 其中包含UC OMS等组件集合
const one = new One({
  rbac: false,
  // 环境，不指定时 One 会根据地址自动指定
  env: _env,
  // uc指定参数
  plugins: {
    request: requestPlugin({
      transformRequest: (conf) => {
        const currentConfig = conf
        currentConfig.headers = {
          ...conf.headers,
          'sdp-app-id': sdpAppId
        }
        return currentConfig
      },
      config: {
        success(data) {
          let newData
          if (typeof data === 'string') {
            // 返回字符串
            newData = {
              data
            }
            // eslint-disable-next-line no-param-reassign
            data = {}
          } else {
            // 返回对象
            newData = { ...data }
          }
          data.status = 200
          data.data = newData
        },
        error() {
          // message.error(data?.data?.code)
        }
      }
    }),
    uc: ucPlugin({
      sdpAppId,
      origins: getUcOrigins()
    }),
    oms: omsPlugin({
      sdpAppId,
      origins: getOmsOrigins()
    })
  }
});

// uc登录专用
async function oneLogin() {
  if (isWebfront) {
    // 用作前台组课时，已经通过window.GeneralUtils.Auth.loginByMacFromUrl登录，直接赋值uc
    one.uc = window.GeneralUtils.Auth.sdpUc;
  } else {
    // 非前台组课，走原逻辑
    const macInfo = getAuthInfo();
    await one.uc.loginByToken(macInfo);
  }
  const userInfo = await one.oms.getUserInfo();
  return userInfo;
}

async function getUsersByIds(userIds) {
  const res = await one.oms.getUsersByIds(userIds);
  return res.items;
}

export { one, oneLogin, getUsersByIds };
