import { handleActions } from 'redux-actions';
import * as T from './actionTypes';

const Students = handleActions(
  {
    [T.GET_STUDENTS]: (state, action) => {
      return {
        ...action.payload
      };
    }
  },
  null
);

export { Students };
