import { Environment } from 'cfg';
import REST from 'utils/rest';

const onlineExamService = new REST(Environment.onlineexamService, 'v1');

onlineExamService.getOnlineExams = onlineExamService.endpoint('online_exams/search');
onlineExamService.enable = onlineExamService.endpoint('online_exams/enabled');
onlineExamService.delete = onlineExamService.endpoint('online_exams/{online_exam_id}');
onlineExamService.getDetail = onlineExamService.endpoint('online_exams/{online_exam_id}');

export default onlineExamService;
