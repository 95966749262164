import React from 'react';
import { Spin } from 'fish';
import { FormattedMessage } from 'react-intl';
// import { LoadingContext } from './loadingContext';

const LoadingComponent = (WrappedComponent) =>
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: true
      };
    }

    setLoading = (status) => {
      this.setState({ loading: status });
    };

    render() {
      const { loading } = this.state;
      return (
        <React.Fragment>
          <Spin spinning={loading} tip={<FormattedMessage id="loading.tip" defaultMessage="Loading..." />}>
            <WrappedComponent {...this.props} loading={loading} setLoading={this.setLoading} />
          </Spin>
        </React.Fragment>
      );
    }
  };

export default LoadingComponent;
