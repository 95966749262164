import { bizCourseAPIHost } from 'cfg/bizCourse';
import REST from 'utils/rest';

const bizCourseService = new REST(bizCourseAPIHost, 'v1');

bizCourseService.passCondition = bizCourseService.endpoint('business_courses/{business_course_id}/pass_rules');
bizCourseService.getCourseExams = bizCourseService.endpoint('business_courses/{business_course_id}/exams/actions/search');
bizCourseService.extraInfos = bizCourseService.endpoint('business_courses/{business_course_id}/extra_infos');
bizCourseService.batchDeleteStudents = bizCourseService.endpoint('business_courses/{business_course_id}/user/actions/batch_delete');
bizCourseService.updateProgress = bizCourseService.endpoint('business_courses/{business_course_id}/progress/actions/manual_update_progress');
bizCourseService.examSearch = bizCourseService.endpoint('business_courses/{business_course_id}/exams/actions/search');
bizCourseService.updateExam = bizCourseService.endpoint('business_courses/{business_course_id}/exams/{exam_id}');
bizCourseService.updatePassStatus = bizCourseService.endpoint('business_courses/{business_course_id}/status/actions/manual_update_pass_status');

bizCourseService.getCourseTemplate = bizCourseService.endpoint('templates/{biz_type}', undefined, false);

export default bizCourseService;
