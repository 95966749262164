/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Table, Divider, Modal, message
} from 'fish'
import dayjs from 'dayjs'
import { closeRecommend, getWorkDetail, getWorkResultDetail, openRecommend } from '../actions'
import WorkResultDetail from './detail'

function ResultTable ({
  data,
  works,
  onLoad
}) {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [workResultData, setWorkResultData] = useState()
  const [workData, setWorkData] = useState()
  const renderTable = (_data) => {
    const columns = [
      {
        title: '作业成果标题',
        dataIndex: 'title',
        key: 'title',
        render: text => text
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        render: text => text
      },
      {
        title: '帐号',
        dataIndex: 'account',
        key: 'account',
        render: text => text
      },
      {
        title: '关联作业',
        dataIndex: 'work_id',
        key: 'work_id',
        filters: works?.map((_work) => ({
          text: _work?.title,
          value: _work?.work_id
        })) || [],
        onFilter: (value, record) => record?.work_id === value,
        render: (text) => {
          const work = works?.find((_w) => _w.work_id === text)
          return work?.title
        }
      },
      {
        title: '提交时间',
        dataIndex: 'submit_time',
        key: 'submit_time',
        render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm')
      },
      {
        title: '是否推荐',
        dataIndex: 'is_open',
        key: 'is_open',
        render: text => text === 1 ? '是' : '否'
      },
      {
        title: '操作',
        key: 'action',
        width: 300,
        render: (text, record) => (
          <span>
            <a
              href="javascript:;"
              onClick={() => {
                setVisible(true)
                const workResultId = record.work_result_id
                const workId = record.work_id
                if (workResultId) {
                  setLoading(true)
                  getWorkDetail(workId).then((_work) => {
                    setWorkData(_work)
                  })
                  getWorkResultDetail(workResultId).then((res) => {
                    setLoading(false)
                    setWorkResultData(res)
                  })
                }
              }}
            >
              查看详情
            </a>
            <Divider type="vertical" />
            {
              record.is_open === 1 ? (
                <a
                  href="javascript:;"
                  onClick={() => {
                    closeRecommend(record.work_result_id).then((res) => {
                      if (res.result === 'success') {
                        message.success('取消推荐成功')
                        onLoad()
                      } else {
                        message.success('取消推荐失败')
                      }
                    })
                  }}
                >
                  取消推荐
                </a>
              ) : (
                <a
                  href="javascript:;"
                  onClick={() => {
                    openRecommend(record.work_result_id).then((res) => {
                      if (res.result === 'success') {
                        message.success('推荐成功')
                        onLoad()
                      } else {
                        message.success('推荐失败')
                      }
                    })
                  }}
                >
                  推荐
                </a>
              )
            }
          </span>
        )
      }
    ]

    return <Table loading={loading} pagination={false} columns={columns} dataSource={_data} bordered />
  }
  
  return (
    <>
      {
        renderTable(data)
      }
      <Modal
        title="查看详情"
        visible={visible}
        onOk={() => {
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
        width={960}
        zIndex={9998}
      >
        <WorkResultDetail
          workResultData={workResultData}
          workData={workData}
        />
      </Modal>
    </>
  )
}

export default ResultTable