import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Iframe from 'modules/common/iframe';
import { withRouter } from 'react-router-dom'
import { AdminMenu } from 'modules/layouts/adminMenu';
import { isWebfront, editable } from 'modules/common/queryString';
import { eleActivityHost } from 'cfg/eleActivity'
import { uc } from 'utils/auth/func'
import * as actions from './actions';
import { formatCode } from 'cfg/env'
import { getUrlQuery } from 'utils/url'

@AdminMenu({ key: 'biz-course-content' })
@withRouter
class Content extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      bizCourseId: props.match.params.id
    }
  }

  componentDidMount() {
    const { bizCourseId } = this.state
    this.props.getCourseDetail({ businessCourseId: bizCourseId });
  }

  render() {
    const { course } = this.props;
    const { bizCourseId } = this.state
    if (!course) return null;

    const query = getUrlQuery()
    const editableFinal = (isWebfront && course.status === 1) ? false : editable
    let url = window.eduBasicLib.getAppURL(`${
      eleActivityHost
    }/?activitySetId=${
      course.activity_set_id
    }&formatCode=${
      formatCode
    }&configCode=${
      course.project_id
    }&isFront=${
      isWebfront
    }&editable=${
      editableFinal
    }&courseId=${
      bizCourseId
    }&audit_policy=${query?.audit_policy || ''}&library_id=${query?.library_id || ''}&biz_type=${query?.biz_type || ''}&sdpLibraryScopeType=${query?.sdpLibraryScopeType || ''}`);
    url = url.replace(/\?activitySetId/, '#/aSetEditor?activitySetId')
    let mac = uc.generateMac(url)
    return <Iframe src={`${url}&__mac=${mac}`} />;
  }
}

Content.propTypes = {
  getCourseDetail: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
  match: PropTypes.object
};

export default connect(
  (state) => {
    return {
      course: state.CourseDetail
    };
  },
  {
    ...actions
  }
)(Content);
