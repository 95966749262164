import React from 'react';
import PropTypes from 'prop-types';

class FrameWindow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, minHeight: -1 };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    const containerHeight = document.querySelector('.edui-crumb__container').clientHeight
    this.setState({
      minHeight: document.documentElement.clientHeight - containerHeight - 15 * 2
    });
  }

  onResize = () => {
    const containerHeight = document.querySelector('.edui-crumb__container').clientHeight
    this.setState({
      minHeight: document.documentElement.clientHeight - containerHeight - 15 * 2
    });
  };

  render() {
    const { minHeight } = this.state;
    const { title, src } = this.props;
    return <iframe 
      title={title}
      src={src}
      frameBorder="0"
      style={{ display: 'block', width: '100%', height: '100%', minHeight: minHeight }}
      allowFullScreen
    />;
  }
}

FrameWindow.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string.isRequired
};

FrameWindow.defaultProps = {
  title: `frame-${new Date().getTime()}`
};

export default FrameWindow;
