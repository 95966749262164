/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import { Upload, Icon, Button, Spin, Modal } from 'fish';
import prettyBytes from 'pretty-bytes';
import { useIntl } from 'react-intl'
import message from '@sdp.nd/edu-message';

import './multi-file.less'

const errorWhenFail = once((errorMsg) => {
  message.error(errorMsg);
}, 3100)

export default function MultiFile({
  getUploadTokens,
  onChange,
  value
}) {
  const [uploading, setUploading] = useState(false)
  const [defaultValue] = useState(() => {
    return value && value.fileList
      ? value.fileList.map((t, index)=> {
          const fakeBlob = new Blob()
          fakeBlob._size = t.size
          return {
              uid: index,
              originFileObj: fakeBlob,
              name: t.name,
              downloadUrl: t.url,
              status: 'done',
              size: t.size
          }
        })
      : []
  })
  const intl = useIntl()
  const tokenInfoRef = useRef()

  function getData(file) {
    const data = {
      name: file.name.replace(/\.(?=[^.]*$)/, `_${Date.now()}.`),
      scope: 1,
      path: tokenInfoRef.current.path
    }
    tokenInfoRef.current = null
    return data
  }

  async function getAction () {
    return new Promise((resolve) => {
      getUploadTokens().then(res => {
        let currentUploadInfo = res.payload ? res.payload : res
        tokenInfoRef.current = currentUploadInfo
        let uploadUrl = `//${currentUploadInfo.server_url}/v0.1/upload?token=${encodeURIComponent(currentUploadInfo.token)
          }&policy=${currentUploadInfo.policy}&date=${encodeURIComponent(currentUploadInfo.date_time)}`;
        resolve(uploadUrl);
      });
    });
  }

  function handleError (file, code) {
    if (code === -110) {
      Modal.error({
        className: 'x-admin-upload-size-modal',
        title: '上传失败',
        content: '文件不能大于800MB！',
        centered: true,
        cancelButtonProps: { style: {
          display: 'none'
        } }
      })
    }
  }

  function handleChange(info) {
    const { status } = info.file;
    switch(status) {
      case 'uploading':
        setUploading(true)
        break
      case 'done':
        setUploading(false)
        onChange(info)
        break
      case 'error':
        setUploading(false)
        errorWhenFail(
          info.file.uid,
          intl.formatMessage({
            id: 'upload.fail',
            defaultMessage: '文件上传失败！'
          })
        )
        break
      default:
        onChange(info)
    }
  }

  function handleRemove(file) {
    return new Promise((resolve, reject) => {
      if (file.error) {
        resolve()
        return
      }
      Modal.confirm({
        content: "确认删除已上传内容",
        centered: true,
        onOk: (close) => {
          resolve()
          close()
        },
        onCancel: (close) => {
          reject()
          close()
        }
      })
    })
  }

  async function handlePreview(file) {
    const size = file._size || file.size
    return size
      ? prettyBytes(size)
      : ''
  }

  return (
    <Spin
      spinning={uploading}
    >
      <Upload
        name="file"
        className="x-edu-admin-extend-reading"
        fileSize={800 * 1024 * 1024}
        action={getAction}
        data={getData}
        onError={handleError}
        onChange={handleChange}
        onRemove={handleRemove}
        showUploadList={{
        showDownloadIcon: false,
        showPreviewIcon: true,
        showRemoveIcon: true
      }}
        defaultFileList={defaultValue}
        previewFile={handlePreview}
        // 分片太小就会GG
        chunkSize={1 * 1024 * 1024}
        chunkUpload
        listType="picture"
      >
        <Button type="primary">
          <Icon type="file" /> 选择文件
        </Button>
        <div>已上传 {value?.fileList?.length || 0} 个文件</div>
      </Upload>
    </Spin>
  )
}

function once(fn) {
  const cacheObj = {}
  return (key, ...args) => {
    if(!cacheObj[key]) {
      fn(...args)
      cacheObj[key] = true
    }
  }
}