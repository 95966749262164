import React from 'react';
import { Editor, Upload, Icon, message } from 'fish';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from '@sdp.nd/edu-modal';

import './richText.less';
import { cdnHost, cdnReadHost, cdnPrefix } from 'cfg/env'

const { Dragger } = Upload;

Editor.url = {
  uEditorHomeUrl: '/fish/ueditor/',
  config: '/fish/ueditor/ueditor.config.js',
  all: '/fish/ueditor/ueditor.all.min.js',
  locale: '/fish/ueditor'
}

@injectIntl
class RichText extends React.PureComponent {
  constructor(props) {
    super(props);
    // 兼容value为null
    let content = (props.value || '').replace(/\$\{cs_host\}/gim, cdnHost.replace('//', ''));
    this.state = {
      visible: false,
      content: content,
      uploadInfo: {}
    };
  }

  // 给其他demo提供上传地址
  getAction = () => {
    return new Promise((resolve) => {
      this.props.getUploadTokens().then(res => {
        let uploadInfo = res.payload ? res.payload : res
        this.setState({ uploadInfo }, () => {
          let uploadUrl = `//${uploadInfo.server_url
            }/v0.1/upload?token=${encodeURIComponent(uploadInfo.token)
            }&policy=${uploadInfo.policy}&date=${encodeURIComponent(uploadInfo.date_time)}`;
          resolve(uploadUrl);
        });
      });
    });
  };

  getData = file => {
    return {
      name: file.name,
      scope: 1,
      path: this.state.uploadInfo.path
    };
  };

  // 获取上传后的图片
  getImage = (url, callback) => {
    if (url) {
      callback({ src: url });
    }
  };

  handleOk = () => {
    this.setState({
      visible: false
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  handleBeforeReady = (_, UE, id) => {
    const { intl } = this.props;

    UE.registerUI(
      'upload-image',
      (editor, uiName) => {
        const btn = new UE.ui.Button({
          name: uiName,
          title: intl.formatMessage({ id: 'richText.imageUpload.title', defaultMessage: '上传图片' }),
          onclick: () => {
            this.setState({ visible: true });
          }
        });
        // 当点到编辑内容上时，按钮要做的状态反射
        editor.addListener('selectionchange', () => {
          const state = editor.queryCommandState(uiName);
          if (state === -1) {
            btn.setDisabled(true);
            btn.setChecked(false);
          } else {
            btn.setDisabled(false);
            btn.setChecked(state);
          }
        });
        // 因为你是添加button,所以需要返回这个button
        return btn;
      },
      undefined,
      id
    );
  };

  handleReady = (editor) => {
    const { content } = this.state;
    const { disabled } = this.props;
    this.editor = editor;
    editor.setContent(content);
    if (disabled) {
      editor.setDisabled()
    }

  };

  handleChange = (value) => {
    this.setState({ content: value }, () => {
      this.triggerChange(value);
    });
  };

  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  render() {
    const { intl } = this.props;
    const that = this;
    const draggerProps = {
      name: 'file',
      showUploadList: true,
      action: this.getAction,
      data: this.getData,
      onChange: (info) => {
        const { status, response } = info.file;
        if (status !== 'uploading') {
          that.setState({
            visible: false
          });
        }
        if (status === 'done') {
          // let url = `${cdnReadHost}/v0.1/download?dentryId=${response.dentry_id}`;
          let url = `${cdnReadHost}${cdnPrefix}${encodeURI(response.path)}`;
          if (url.startsWith('//')) {
            url = 'https:' + url;
          }
          this.getImage(url, (p) => {
            that.editor.execCommand('insertimage', [p]);
          });
        } else if (info.file.status === 'error') {
          message.error(intl.formatMessage({ id: 'upload.fail', defaultMessage: '上传失败!' }));
        }
      }
    };

    let editorConfig = {
      wordCount: false,
      toolbars: [['source', 'removeformat', '|', 'undo', 'redo', '|', 'bold', 'underline', 'backcolor', 'forecolor', '|', 'fontfamily', 'fontsize', '|', 'justifyleft', 'justifyright', 'justifycenter', 'justifyjustify', '|', 'link', 'unlink']],
      iframeUrlMap: {
        link: '/fish/ueditor/dialogs/link/link.html'
      }
    };

    return (
      <div>
        <Editor editorConfig={editorConfig} onBeforeReady={this.handleBeforeReady} onReady={this.handleReady} onChange={this.handleChange} />
        <Modal title={<FormattedMessage id="richText.imageUpload.title" defaultMessage="图片上传" />} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} footer={null} maskStyle={{ zIndex: 999999 }} wrapClassName="editor-demo-huge-zindex">
          <div className="editor-demo-dragger-wrap">
            <Dragger {...draggerProps}>
              <Icon type="plus" />
            </Dragger>
          </div>
        </Modal>
      </div>
    );
  }
}

RichText.propTypes = {
  getUploadTokens: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  intl: PropTypes.object,
  disabled: PropTypes.bool
};

export default RichText;
