import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Menu, Icon, Breadcrumb, Dropdown } from 'fish';
import { SkinContext } from 'theme/skin';
import { connect } from 'react-redux';
import { isWebfront, isReview } from 'modules/common/queryString';
import getReturnUrl from 'utils/getReturnUrl';
import { defaultMenus, webfrontMenus, webfrontReviewMenus } from './menu';
import { getQueryStringByName } from '../common/urlHelper';
// import VerifyComp from '../../component/VerifyComp';
import { getUrlQuery } from 'utils/url';
import feedBookHostGateway from 'dataProvider/feedBook';
import auditService from 'dataProvider/auditService';
import { withRouter } from 'react-router-dom';
import bizCourseGateway from 'dataProvider/bizCourseGateway';
import { ReviewTip } from '@sdp.nd/ndr-resource-review';
import { one } from 'utils/one';
import { env, orgId } from 'cfg/env';
import './index.less';

export const { Provider, Consumer } = React.createContext({});

const { SubMenu } = Menu;

const GLOBAL = 'global';
const SOURCE = 'channel';

export const AdminMenu = (options) => {
  let menus = (options && options.items) || defaultMenus;
  if (isWebfront) {
    menus = !isReview ? webfrontMenus : webfrontReviewMenus; // 前台组课有两个菜单，一个是完整的组课菜单，一个是UGC审核菜单
  }
  const noMenu = (options && options.noMenu) || false;
  const menuKey = (options && options.key) || '';
  // const selectedKey = (options && options.selectedKey) || '';

  return (WrappedComponent) => {
    @injectIntl
    @withRouter
    class AdminMenuComponent extends React.Component {
      constructor(props) {
        super(props);
        const customMenu = props.bizCourseTemplate && props.bizCourseTemplate.custom_menu;
        if (customMenu) {
          menus.catalog[0].children.forEach((child) => {
            if (!customMenu.includes(child.id)) {
              child.hide = true;
            }
          });
        }
        this.hash = this.convertToHash(menus.catalog);
        let selectedKey = this.getCurrentSelectKey();
        if (!selectedKey) {
          this.state = {
            skin: { theme: 'blue' },
            currentItem: props.selectedKey ? this.hash[props.selectedKey] : this.getItem()
          };
        } else {
          this.state = { skin: { theme: 'blue' }, currentItem: this.hash[selectedKey] };
        }

        document.title = this.getItemTitle(this.state.currentItem);
        this.state = {
          ...this.state,
          violationData: {},
          course: {}
        };
      }

      componentDidMount() {
        !!this.props.selectedKey && this.props.onClick(this.state.currentItem);
        // this.fetchIfViolation();
        this.fetchCourse();
        this.fetchIfViolation();
      }

      onThemeMenuClick = ({ key }) => {
        this.setState({ skin: { theme: key } });
      };

      fetchCourse = async () => {
        try {
          const getID = this.props.match.params.id;
          const course = await bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/manage_infos').replace('business_course_id', getID).get();
          this.setState({
            course
          });
        } catch (e) {
          this.setState({
            course: {}
          });
        }
      };

      getManualAuditStatus = async (bizCourseId) => {
        const query = getUrlQuery();
        const res = await auditService.auditStatus
        .query({
          scope_type: query.sdpLibraryScopeType,
          scope_id: query.library_id,
          biz_type: query.biz_type,
          biz_id: bizCourseId
        })
        .get({
          headers: query?.['sdp-audit-biz-type'] ? {
            'sdp-audit-biz-type': query?.['sdp-audit-biz-type']
          } : {}
        })
        return res
      }

      fetchIfViolation = async () => {
        try {
          const query = getUrlQuery();
          const getID = this.props.match.params.id;
          // 获取机审结果
          let data = await feedBookHostGateway.detail.replace('contentId', getID).get();
          console.log('fetchIfViolation = ', data)
          // 机审+人审
          if (getID && query.audit_policy.indexOf('machine_manual') === 0) {
            // 机审+人审都不通过才展示不通过表示
            const res = await this.getManualAuditStatus(getID)
            data = {
              if_violation: data?.if_violation && res?.biz_status === 'manual_audit_block'
            }
          }
          console.log('violationDataviolationData', data);
          this.setState({
            violationData: data || {}
          });
        } catch (e) {
          console.log('eeeeeeeee', e);
        }
      };

      getParams = (url) => {
        let params = [];
        const PATH_NAME_MATCHER = /:([\w\d]+)/g;
        let pathMatch = '';

        /* eslint no-cond-assign: "off" */
        while ((pathMatch = PATH_NAME_MATCHER.exec(url)) !== null) {
          params.push(pathMatch[1]);
        }
        /* eslint no-cond-assign: "error" */

        return params;
      };

      getThemeMenu = () => {
        return (
          <Menu onClick={this.onThemeMenuClick}>
            <Menu.Item key='blue'>
              <a href='javascript:;'>蓝色</a>
            </Menu.Item>
            <Menu.Item key='red'>
              <a href='javascript:;'>红色</a>
            </Menu.Item>
          </Menu>
        );
      };

      getCurrentSelectKey = () => {
        // debugger;
        // let result = '';

        // let path = `#${this.props.match.path}`; // window.location.hash.toLowerCase();
        // let keys = Object.keys(this.hash);
        // for (let i = 0; i < keys.length; i++) {
        //   let item = this.hash[keys[i]];
        //   if (item.url === path) {
        //     result = item.id;
        //     break;
        //   }
        // }

        // return result;
        return menuKey;
      };

      onItemClick = (event) => {
        let currentItem = this.hash[event.key];
        this.setState({ currentItem });
        this.props.onClick(currentItem);

        document.title = currentItem.title;
      };

      getItem = (key) => {
        if (!key) {
          [key] = Object.keys(this.hash);
        }

        return this.hash[key];
      };

      getItemTitle = (item) => {
        return item.i18n ? this.props.intl.formatMessage({ id: item.i18n, defaultMessage: item.title }) : item.title;
      };

      getItemDescrption = (item) => {
        let desc = item.descI18n ? this.props.intl.formatMessage({
          id: item.descI18n,
          defaultMessage: item.descrption
        }) : item.descrption;

        return desc ? <div className='edui-header-content'>{desc}</div> : null;
      };

      getSubMenu = (item) => {
        return (
          <SubMenu
            key={item.id}
            title={
              <span>
                {/* <i className={item.class} /> */}
                <Icon type='appstore' />
                <span>{this.getItemTitle(item)}</span>
              </span>
            }
          >
            {item.children &&
              item.children.map((sub) => {
                return sub.children && sub.children.length > 0 ? this.getSubMenu(sub) : this.getSubMenuItem(sub);
              })}
          </SubMenu>
        );
      };

      replaceValues = (url) => {
        const { course } = this.state
        // let values = url.match(/(?<=\{)[^}]*(?=\})/g);
        let values = url.match(/{[^}]+}/g);
        values &&
        values.forEach((value) => {
          let tempValue = value
            .replace(/{/g, '')
            .replace(/}/g, '')
            .replace(/window./g, '');

          let properties = tempValue.split('.');
          let current = window;
          if (properties && properties.constructor === Array) {
            properties.forEach((prop) => {
              current = current[prop];
            });
          } else {
            current = window[tempValue];
          }
          if (!current) {
            current = getQueryStringByName(tempValue, window.location.search);
          }

          if (!current && tempValue === GLOBAL) {
            current = GLOBAL;
          }
          if (!current && tempValue === SOURCE) {
            current = SOURCE;
          }

          if (!current && tempValue === 'activity_set_id') {
            current = course?.activity_set_id;
          }

          if (current && tempValue === 'returnUrl') {
            current = encodeURIComponent(current);
          }

          let regex = new RegExp(`${value}`, 'g');
          url = url.replace(regex, current !== undefined ? current : '');
        });

        return window.eduBasicLib.getAppURL(url);
      };

      getSubMenuItem = (item) => {
        if (item.id !== 'biz-course-edit' && item.hide) {
          return null;
        }
        let { url } = item;
        let params = this.getParams(url);
        let pathParams = this.props.match.params;
        params.forEach((param) => {
          url = url.replace(`:${param}`, pathParams[param] ? pathParams[param] : window.param || getQueryStringByName(param, window.location.search));
        });

        url = `${window.location.origin}${this.replaceValues(url)}`;
        return (
          <Menu.Item key={item.id}>
            <a className='admin-menu__link' href={url}>
              {item.class ? <i className={item.class} /> : null}
              <span>{this.getItemTitle(item)}</span>
            </a>
          </Menu.Item>
        );
      };

      getBreadcrumb = () => {
        const cata = this.getHierarchy(this.state.currentItem.id).reverse();

        return (
          <div className='edui-head'>
            <Breadcrumb>
              {cata.map((label, index) => {
                return (
                  <Breadcrumb.Item key={label.id}>
                    {index <= 0 ? <Icon type='home' style={{ paddingRight: 10 }} /> : null}
                    {label.id !== this.state.currentItem.id && (label.url ? <a
                        href={!label.url ? 'javascript:;' : window.eduBasicLib.getAppURL(label.url)}>{this.getItemTitle(label)}</a> :
                      <span>{this.getItemTitle(label)}</span>)}
                    {label.id === this.state.currentItem.id && <span>{this.getItemTitle(label)}</span>}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
            <div className='edui-head-heading'>{this.getItemTitle(this.state.currentItem)}</div>
            {this.getItemDescrption(this.state.currentItem)}
          </div>
        );
      };

      getHierarchy = (itemKey) => {
        let hierarchy = [];
        let item = this.hash[itemKey];
        hierarchy.push(item);

        if (item.parentId) {
          hierarchy = hierarchy.concat(this.getHierarchy(item.parentId));
        }

        return hierarchy;
      };

      convertToHash = (data, parent) => {
        let result = {};
        if (data instanceof Array) {
          data.forEach((value) => {
            Object.assign(result, this.convertToHash(value, parent));
          });
        } else {
          if (data.children && data.children.length > 0) {
            data.children.forEach((item) => {
              Object.assign(result, this.convertToHash(item, data));
            });
          }

          result[data.id] = {
            id: data.id,
            title: data.i18n ? this.props.intl.formatMessage({
              id: data.i18n,
              defaultMessage: data.title
            }) : data.title,
            i18n: data.i18n,
            parentId: parent ? parent.id : undefined,
            url: data.url,
            class: data.class,
            descrption: data.descrption
          };
        }

        return result;
      };

      getDefaultOpenKeys = (selectedKey) => {
        let openKeys = [];

        while (selectedKey) {
          let { parentId } = this.hash[selectedKey];
          if (parentId) {
            openKeys.push(parentId);
            selectedKey = parentId;
          } else {
            openKeys.push(selectedKey);
            selectedKey = undefined;
          }
        }

        return openKeys;
      };

      getSideHeader = () => {
        const { back } = menus;
        if (isWebfront) return null; // 前台组课隐藏返回
        const returnUrl = getReturnUrl();
        return (
          <div className='edui-layout__side-header'>
            <a href={returnUrl}>
              <Icon type='left' />
              <span>{this.getItemTitle(back)}</span>
            </a>
          </div>
        );
      };

      getEduiCrumbNav = () => {
        if (isWebfront) return null; // 前台组课一期不需要面包屑
        return <div className='edui-crumb__nav'>{this.getBreadcrumb()}</div>;
      };

      // renderVerify = (course) => {
      //   const { biz_type, audit_policy } = getUrlQuery();
      //   if (window.__global_env ==='product') return null //生产环境不需要审核功能
      //   if (biz_type !== 't_course' && biz_type !=='s_course') return null;

      //   let show = false;

      //   if (!course) return;
        
      //   if (audit_policy) return null;

      //   if (course.ext_info?.hasOwnProperty('mini_audit_status') && (course.ext_info?.mini_audit_status !== 2)) {
      //     show = true;
      //   }
      //   if (!course.ext_info?.hasOwnProperty('mini_audit_status') || (course.ext_info?.mini_audit_status === 2)) {
      //     show = false;
      //   }
      //   return show && <VerifyComp id={this.props.match?.params?.id} />;
      // };

      render() {
        const query = getUrlQuery();
        const { openKeys } = this.props;
        const getID = this.props.match.params.id;
        const { currentItem, violationData, course } = this.state;
        let selectItem = currentItem.id ? [currentItem.id] : [];
        const { catalog: menuList } = menus;
        let defaultOpenKeys = openKeys.length ? openKeys : this.getDefaultOpenKeys(currentItem.id);
        let reviewTipOptions = {
          ucInstance: one.uc,
          env,
          contentId: getID,
          orgId,
          callback: () => window.location.href = getReturnUrl()
        }
        let manualAuditBlock = true
        if (query?.audit_policy?.includes('machine_manual')) {
          manualAuditBlock = violationData.if_violation
          reviewTipOptions = {
            ...reviewTipOptions,
            libraryId: query?.library_id,
            libraryType: query?.sdpLibraryScopeType,
            contentType: query?.biz_type,
            auditPolicy: query?.audit_policy,
          }
          if (query?.['sdp-audit-biz-type']) {
            reviewTipOptions.auditBizType = query?.['sdp-audit-biz-type']
          }
        }

        return (
          <div>
            <Provider value={violationData}>
              {
                manualAuditBlock && <ReviewTip {...reviewTipOptions} />
              }
              {/* {this.renderVerify(course)} */}
              <div className={`edui-layout ${isWebfront ? 'edui-layout-webfront' : ''}`}>
                <div className='edui-layout__container'>
                  <div className={`edui-layout__sidecontainer ${noMenu ? 'edui-layout__sidecontainer--nomenu' : ''}`}>
                    <div className='edui-layout__side'>
                      {this.getSideHeader()}
                      <div className='edui-layout__sidebar edui-layout--position'>
                        <Menu
                          selectedKeys={selectItem}
                          mode='inline'
                          inlineIndent={36}
                          defaultOpenKeys={defaultOpenKeys}
                          onClick={(event) => {
                            this.onItemClick(event);
                          }}
                        >
                          {menuList &&
                            menuList.map((menu) => {
                              return menu.children && menu.children.length > 0 ? this.getSubMenu(menu) : this.getSubMenuItem(menu);
                            })}
                        </Menu>
                      </div>
                    </div>
                    <div className='edui-layout__container'>
                      <div className='edui-layout__panel edui-layout--position'>
                        <div className='edui-layout--global__header' style={{ display: 'none' }}>
                          <Dropdown overlay={this.getThemeMenu()}>
                            <span className='eduui-header__dropdown'>切换主题</span>
                          </Dropdown>
                        </div>
                        <div className='edui-crumb__container'>{this.getEduiCrumbNav()}</div>
                        <div className='edui-layout__body'>
                          <SkinContext.Provider value={this.state.skin}>
                            <WrappedComponent {...this.props} />
                          </SkinContext.Provider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Provider>
          </div>
        );
      }
    }

    AdminMenuComponent.propTypes = {
      intl: PropTypes.object,
      data: PropTypes.object,
      openKeys: PropTypes.array,
      selectedKey: PropTypes.string,
      match: PropTypes.object.isRequired,
      onClick: PropTypes.func,
      bizCourseTemplate: PropTypes.object
    };

    AdminMenuComponent.defaultProps = {
      onClick: () => {
      },
      selectedKey: undefined,
      openKeys: []
    };

    return connect((state) => {
      return {
        bizCourseTemplate: state.bizCourseTemplate
      };
    })(AdminMenuComponent);
  };
};
