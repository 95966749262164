import { handleActions } from 'redux-actions';
import * as T from './actionTypes';

const Plugins = handleActions(
  {
    [T.GET_COURSE_PLUGINS]: (state, action) => {
      return {
        ...state,
        items: action.payload ? action.payload : []
      };
    }
  },
  []
);

const CourseDetail = handleActions(
  {
    [T.GET_COURSE_DETAIL]: (state, action) => {
      return {
        ...action.payload
      };
    }
  },
  null
);

const CourseExtraInfos = handleActions(
  {
    [T.GET_BUSINESS_COURSE_EXTRA_INFOS]: (state, action) => {
      return {
        ...state,
        data: action.payload
      };
    }
  },
  null
);

const UpdatePassCondition = handleActions(
  {
    [T.UPDATE_PASS_CONDITION]: (state, action) => {
      return {
        ...state,
        data: action.payload
      };
    }
  },
  null
);

const CourseExams = handleActions(
  {
    [T.GET_BUSINESS_COURSE_EXAMS]: (state, action) => {
      return {
        ...state,
        data: action.payload
      };
    }
  },
  null
);

const OnlineExams = handleActions(
  {
    [T.GET_ONLINE_EXAMS]: (state, action) => {
      return {
        ...state,
        data: action.payload
      };
    }
  },
  null
);

const ExamManageInfo = handleActions(
  {
    [T.GET_EXAM_MANAGE_INFO]: (state, action) => {
      return {
        ...action.payload
      };
    }
  },
  null
);

export { ExamManageInfo, OnlineExams, CourseExams, UpdatePassCondition, Plugins, CourseDetail, CourseExtraInfos };
