export const GET_PASS_CONDITION = 'get_pass_condition';
export const UPDATE_PASS_CONDITION = 'update_pass_condition';
export const GET_BUSINESS_COURSE_EXAMS = 'get_business_course_exams';
export const GET_OLD_EXAMS = 'get_old_exams';
export const GET_ONLINE_EXAMS = 'get_online_exams';
export const GET_BUSINESS_COURSE_EXTRA_INFOS = 'get_business_course_extra_infos';
export const GET_ALL_EXAMS_BY_IDS = 'get_all_exams_by_ids';
export const GET_COURSE_DETAIL = 'get_course_detail';
export const GET_COURSE_PLUGINS = 'get_course_plugins';
export const GET_EXAM_MANAGE_INFO = 'GET_EXAM_MANAGE_INFO';
export const GET_EXAM_MANAGE_INFO_BY_IDS = 'GET_EXAM_MANAGE_INFO_BY_IDS';
