import { handleActions } from 'redux-actions';
import * as T from './actionTypes';

// const UploadSession = handleActions(
//   {
//     [T.GET_UPLOAD_SESSION]: (state, action) => {
//       return {
//         ...action.payload
//       };
//     }
//   },
//   null
// );

const CourseDetail = handleActions(
  {
    [T.GET_COURSE_DETAIL]: (state, action) => {
      return {
        ...action.payload
      };
    }
  },
  null
);

export { CourseDetail };
