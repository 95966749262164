import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Upload, Spin, Button } from 'fish';
import message from '@sdp.nd/edu-message';
import NoAccept from './noAccept';

import './cover.less';
import { cdnPrefix, cdnReadHost } from 'cfg/env';
import { getUrlRelativePath } from 'utils/url';

const DEFAULT_COVER_URL = require('../../../asset/default-cover.png');

/* 文件名校验，不包含?&://\% */
function isValidFileName(fileName) {
  const invalidChars = /[?&:\/\\%]/;
  return !invalidChars.test(fileName);
}

@injectIntl
class Cover extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploadInfo: {},
      uploading: false,
      percent: 0,
      coverUrl: props.value && props.value.front_cover_object_url ? props.value.front_cover_object_url : DEFAULT_COVER_URL
    };
  }

  getUploadProps = () => {
    const { intl } = this.props;
    const props = {
      name: 'file',
      fileSize: 2 * 1024 * 1024,
      fileType: '.jpg,.jpeg,.png',
      accept: 'image/jpeg,image/png',
      multiple: false,
      showUploadList: false,
      data: this.getData,
      action: this.getAction,
      beforeUpload: this.beforeUpload,
      onError: this.handleError,
      onChange: (info) => {
        const { status, response } = info.file;
        if (status !== 'uploading') {
          this.setState({ uploading: true, percent: info.percent || 0 });
        }
        if (status === 'done') {
          console.log('11111111111', `${cdnReadHost}${cdnPrefix}${encodeURI(response.path)}`);
          this.setState({
              uploading: false,
              percent: info.percent || 0,
              coverUrl: `${cdnReadHost}${cdnPrefix}${encodeURI(response.path)}`
            },
            () => {
              let changedValue = {
                front_cover_object_id: info.file.response.dentry_id,
                front_cover_object_url: this.state.coverUrl
              };
              this.triggerChange(changedValue);
            }
          );
        } else if (status === 'error') {
          this.setState({ uploading: false, percent: info.percent || 0 });
          message.error(intl.formatMessage({ id: 'cover.upload.fail', defaultMessage: '封面上传失败！' }));
        }
      }
    };

    return props;
  };

  getData = (file) => {
    return {
      name: file.name.replace(/\.(?=[^.]*$)/, `_${Date.now()}.`),
      scope: 1,
      path: this.state.uploadInfo.path
    };
  };

  getAction = () => {
    return new Promise((resolve) => {
      this.props.getUploadTokens().then(res => {
        let uploadInfo = res.payload ? res.payload : res;
        this.setState({ uploadInfo }, () => {
          let uploadUrl = `//${uploadInfo.server_url}/v0.1/upload?token=${encodeURIComponent(uploadInfo.token)
          }&policy=${uploadInfo.policy}&date=${encodeURIComponent(uploadInfo.date_time)}`;
          resolve(uploadUrl);
        });
      });
    });
  };

  beforeUpload = (file) => {
    if (!isValidFileName(file.name)) {
      message.error('文件名不能包含?&://\%字符')
      return Promise.reject()
    }
    this.setState({ percent: 0 });
  };

  getUploadPercent = () => {
    return `${this.state.percent}%`;
  };

  handleError = (file, code) => {
    const { intl } = this.props;
    if (code === -130) {
      message.error(intl.formatMessage({ id: 'cover.upload.fileType', defaultMessage: '只能上传JPG, PNG图片！' }));
    }
    if (code === -110) {
      message.error(intl.formatMessage({ id: 'cover.upload.fileSize', defaultMessage: '图片文件不能大于2MB！' }));
    }
  };

  handleUseDefaultCover = () => {
    this.setState({ coverUrl: DEFAULT_COVER_URL }, () => {
      let changedValue = { front_cover_object_id: null, front_cover_object_url: '' };
      this.triggerChange(changedValue);
    });
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue
      });
    }
  };

  /**
   * 不同业务场景下的封面url 统一处理
   * @param coverUrl
   * @returns {string} img的url
   */
  resolveCoverUrlDiffrence = (coverUrl) => {
    let url = coverUrl
    if ((coverUrl.indexOf('http:') > -1) || (coverUrl.indexOf('https:') > -1)) {
      url = coverUrl
    } else if (coverUrl.indexOf('//') > -1 && (/http(s)?:\//.test(coverUrl))) {
      url = `https:${coverUrl}`
    }
   return url
  }

  render() {
    const uploadProps = this.getUploadProps();
    const { uploading, coverUrl } = this.state;
    const tip = this.getUploadPercent();
    const { disabled, violationData } = this.props;

    let urls = this.resolveCoverUrlDiffrence(coverUrl)

    const getFilterData = () => {
      if (!violationData) return [];
      const imgSuccess = violationData.details?.success?.images || [];
      const imgError = violationData.details?.error?.images || [];
      let images = imgSuccess.concat(imgError);

      const videoSuccess = violationData.details?.success?.videos || [];
      const videoError = violationData.details?.error?.videos || [];
      let videos = videoSuccess.concat(videoError);

      const list = images.concat(videos);
      if (list.length === 0) return []
      const generateStr = getUrlRelativePath(urls);
      const getFilter = list.filter(item => item.path === generateStr);
      return getFilter ?? [];
    };

    const judgeWeiGui = () => {
      return  violationData && violationData.if_violation
      // if (!violationData?.if_violation) return false;
      // return getFilterData()?.length !== 0;
    };

    console.log('urls',  urls);

    return (
      <React.Fragment>
        <div className='cover__image'>
          <Spin spinning={uploading} tip={tip}>
            <div className={'wrap_img'}>
              <NoAccept flag={judgeWeiGui()} />
              <img alt='cover' src={getFilterData()?.[0]?.url || urls} />
            </div>
          </Spin>
        </div>
        <div className='cover__action__btns'>
          <Upload {...uploadProps}>
            <Button disabled={uploading || disabled} shape='round'>
              <FormattedMessage id='cover.action.uploadCover' defaultMessage='修改封面' />
            </Button>
          </Upload>
          <Button shape='round' onClick={this.handleUseDefaultCover}
                  disabled={coverUrl === DEFAULT_COVER_URL || disabled}>
            <FormattedMessage id='cover.action.useDefaultCover' defaultMessage='使用默认封面' />
          </Button>
        </div>
        <div className='cover__action__tip'>
          <p>
            <FormattedMessage id='cover.tip.default' defaultMessage='* 若无上传新封面，则使用默认封面' />
          </p>
          <p>
            <FormattedMessage id='cover.tip.fileType' defaultMessage='* 仅支持JPG、JPEG、PNG等图片，文件小于2MB' />
          </p>
          <p>
            <FormattedMessage id='cover.tip.fileSize' defaultMessage='* 建议尺寸：1200*800或3：2比例尺寸' />
          </p>
        </div>
      </React.Fragment>
    );
  }
}

Cover.propTypes = {
  getUploadTokens: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  intl: PropTypes.object,
  disabled: PropTypes.bool
};

export default Cover;
