/* eslint-disable no-alert */
import React, { useRef } from 'react'
import './style.less'
import { appId, env } from 'cfg/env'
import { getLoginUrl } from 'utils/auth/func'

const baseURL = `&lang=zh-CN&context_id=library%3A${'e4270790-dae4-4b75-95c4-d56989892774'}&sdp-app-id=${appId
  }&return_url=${encodeURIComponent(window.location.href)}`

function jumpTo(subPath) {
  window.location.href = `${window.location.origin}${subPath}`
}

export default function Home() {
  const xCourseInput = useRef(null)
  const tCourseInput = useRef(null)
  if (process.env.NODE_ENV !== 'test') {
    return (
      <div>.</div>
    )
  }
  function login() {
    window.location.href = getLoginUrl()
  }

  function createCourse(bizType) {
    jumpTo(`/create?biz_type=${bizType}${baseURL}`)
  }

  function editCourse(bizType) {
    const itemId = bizType === 't_course'
      ? tCourseInput.current.value
      : xCourseInput.current.value
    if (!itemId) {
      alert('输入id再编辑，新建后会自动获取id')
      return
    }
    jumpTo(`/frame/${itemId}/edit?biz_type=${bizType}${baseURL}`)
  }

  return (
    <>
      <h3>环境变量{process.env.NODE_ENV}</h3>
      <h3>当前配置环境{env}</h3>
      <div className="local-test-menu">
        <button
          onClick={login}
          type="button"
        >
          登录
        </button>

        <button
          onClick={() => createCourse('x_course')}
          type="button"
        >
          新建同步课 x-course
        </button>
        <div className="input">
          <span>资源id</span>
          <span>
            <input
              ref={xCourseInput}
              defaultValue={window.sessionStorage.getItem(`x_course-id`) || ''}
            />
          </span>
        </div>
        <button
          onClick={() => editCourse('x_course')}
          type="button"
        >
          编辑同步课 X-course
        </button>
        <br />
        <br />
        <button
          onClick={() => createCourse('t_course')}
          type="button"
        >
          新建研修课 t-course
        </button>
        <div className="input">
          <span>资源id</span>
          <span>
            <input
              ref={tCourseInput}
              defaultValue={window.sessionStorage.getItem(`t_course-id`) || ''}
            />
          </span>
        </div>
        <button
          onClick={() => editCourse('t_course')}
          type="button"
        >
          编辑研修课 t-course
        </button>
      </div>
    </>)
}
