import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Card, Switch } from 'fish';
import message from '@sdp.nd/edu-message';
import { AdminMenu } from 'modules/layouts/adminMenu';
import LoadingComponent from 'modules/common/loading';
import { uc } from 'utils/auth/func'
import { xEduAdminHost } from 'cfg/xEduAdmin'
import * as Actions from './actions';
import { resProfileHost } from 'cfg/env'
import 'modules/more/css/index.less';
import { appId } from 'cfg/env'
import { getQueryStringByName } from 'modules/common/urlHelper'

const USE_QA = 'use_qa'

@AdminMenu({ key: 'biz-course-more' })
@withRouter
@LoadingComponent
class More extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      bizCourseId: props.match.params.id,
      switchChecked: {}
    }
  }

  goto = (page) => {
    let url = window.eduBasicLib.getAppURL(page.url);
    let mac = uc.generateMac(url)
    window.open(`${url}&__mac=${mac}`);
  };

  /* 更新开关状态 */
  handleSwitchChange = async (checked, key, init) => {
    const { switchChecked, bizCourseId } = this.state
    if (!init && [USE_QA].includes(key)) {
      const { course, updateDetail } = this.props;
      const options = {
        businessCourseId: bizCourseId,
        data: {
          ...course,
          ext_info: {
            ...course.ext_info || {},
            [key]: checked
          }
        }
      }
      await updateDetail(options).then(
        () => {
          this.setState({
            switchChecked: {
              ...switchChecked,
              [key]: checked
            }
          })
        },
        () => {
          message.error(`${checked ? '开启' : '关闭'}失败`);
        }
      );
    } else {
      this.setState({
        switchChecked: {
          ...switchChecked,
          [key]: checked
        }
      })
    }
  }

  initQAConfig = async () => {
    const { course } = this.props;
    this.handleSwitchChange(course?.ext_info?.[USE_QA] || false, USE_QA, true)
  }

  componentDidMount() {
    const { bizCourseId } = this.state
    Promise.all([
      this.props
        .getManagementPlugins({
          businessCourseId: bizCourseId,
          type: 'extend'
        }),
      this.props
        .getCourseDetail({ 
          businessCourseId: bizCourseId 
        })
      ]).then(() => {
        this.initQAConfig()
        this.props.setLoading(false);
    })
  }

  render() {
    const { plugins, course, loading } = this.props;
    if(loading) {
      return <></>
    }
    const { bizCourseId, switchChecked } = this.state
    const bizType = getQueryStringByName('biz_type')
    const displayPlugins = [
      {
        display_name: '智能标签',
        description: '',
        code: 'resource-profile',
        display_data: '',
        config_pages: [{
          title: '智能标签',
          url: `${resProfileHost}/#/resourceProfile?sdp-app-id=${appId}&biz_id=${bizCourseId}&biz_type=${bizType}`
        }]
      },
      ...plugins || [],
      {
        display_name: '机构设置',
        description: '关联机构信息',
        code: 'x-provider',
        display_data: '',
        config_pages: [{
          title: '机构设置',
          url: `${xEduAdminHost}/provider/relation?contentId=${bizCourseId}&contentName=${encodeURIComponent(course?.name)}&contentType=${course?.biz_type}&sider=0&header=0`
        }]
      },
      {
        display_name: '作业成果管理',
        description: '针对课程下所有学员所提交的作业成果进行管理',
        code: 'work',
        display_data: '',
        config_pages: [{
          title: '作业成果管理',
          url: `${window.location.origin}/frame/${bizCourseId}/work/result?contentId=${bizCourseId}`
        }]
      },
      {
        display_name: '讨论设置',
        description: '开启后，前台的课程主页中会增加“课程讨论”模块',
        code: 'elearning_qa_api',
        display_data: '',
        config_pages: [{
          type: 'switch',
          key: USE_QA,
          checked: switchChecked[USE_QA] || false,
          checkedChildren: '开启中',
          uncheckedChildren: '关闭中',
        }]
      }
    ]
    console.log('displayPlugins', displayPlugins)
    return (
      <Card bordered={false}>
        {(!displayPlugins || displayPlugins.length <= 0) && (
          <div>
            <div className="item-nodata">
              <FormattedMessage id="more.nodata" defaultMessage="暂无数据" />
            </div>
          </div>
        )}

        {displayPlugins &&
          displayPlugins.map((plugin) => {
            return (
              <ul className="more-plugin" key={plugin.code}>
                <li className="plugin-item">
                  <div className="plugin-item-content">
                    <span className="plugin-item__title">{plugin.display_name}</span>
                    <p className="plugin-item__desc">{plugin.description}</p>
                    <div className="plugin-item__display">{plugin.display_data}</div>
                  </div>
                  <div className="plugin-item-btns">
                    {plugin.config_pages &&
                      plugin.config_pages.map((page) => {
                        if (page?.type === 'switch') {
                          return (
                            <span className="plugin-item-btns__switch">
                              <Switch checked={page.checked} onChange={(_checked) => {
                                this.handleSwitchChange(_checked, page.key)
                              }} />
                              <span>{page.checked ? page.checkedChildren : page.uncheckedChildren}</span>
                            </span>
                          )
                        } else {
                          return (
                            <a
                            key={page.title}
                            href=""
                            onClick={this.goto.bind(this, page)}
                          >
                            {page.title}
                          </a>
                          )
                        }
                      })}
                  </div>
                </li>
              </ul>
            );
          })}
      </Card>
    );
  }
}

More.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  getManagementPlugins: PropTypes.func,
  plugins: PropTypes.array,
  match: PropTypes.object,
  course: PropTypes.object,
  getCourseDetail: PropTypes.func.isRequired,
  updateDetail: PropTypes.func
};

export default connect(
  (state) => {
    return {
      course: state.CourseDetail,
      plugins: state.morePlugins.data
    };
  },
  { ...Actions }
)(More);
