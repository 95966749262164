
import { workBusinessAPIHost } from 'cfg/workBusiness'
import REST from 'utils/rest';

const workBusiness = new REST(workBusinessAPIHost);

workBusiness.create = workBusiness.endpoint('v1/work/admin/create')

workBusiness.createRelation = workBusiness.endpoint('v1/work/admin/create_relation')

workBusiness.getWorkList = workBusiness.endpoint('v1/work/admin/{course_id}/work_list')

workBusiness.updateOrder = workBusiness.endpoint('v1/work/admin/{course_id}/update_order')

workBusiness.online = workBusiness.endpoint('v1/work/admin/{work_relation_id}/online')

workBusiness.offline = workBusiness.endpoint('v1/work/admin/{work_relation_id}/offline')

workBusiness.update = workBusiness.endpoint('v1/work/admin/{work_id}/update')

workBusiness.detail = workBusiness.endpoint('v1/work/admin/{work_id}/detail')

workBusiness.delete = workBusiness.endpoint('v1/work/admin/{work_id}/delete')

workBusiness.getWorkResultList = workBusiness.endpoint('v1/work_result/admin/{course_id}/work_result_list')

workBusiness.getWorkResultDetail = workBusiness.endpoint('v1/work_result/admin/{work_result_id}/work_result_detail')

workBusiness.closeRecommend = workBusiness.endpoint('v1/work_result/admin/{work_result_id}/close')

workBusiness.openRecommend = workBusiness.endpoint('v1/work_result/admin/{work_result_id}/open')


export default workBusiness;
