import { createAction } from 'redux-actions';
import * as $dp from 'dataProvider';
import * as T from './actionTypes';

export const getCourseDetail = createAction(T.GET_COURSE_DETAIL, (options) => $dp.bizCourseGateway.detail.replace('business_course_id', options.businessCourseId).get());

export const updateDetail = createAction(T.UPDATE_COURSE_DETAIL, (options) =>
  $dp.bizCourseGateway.updateDetail
    .replace('business_course_id', options.businessCourseId)
    .send(options.data)
    .put()
);
