import queryString from 'query-string'

/**
 * 获取 url query 参数
 * 在 hash 模式下，如果 query 在 hash 后面 location.query 拿不到值
 * 因此通过 location.href 来获取
 */
export function getUrlQuery() {
  const query = window.location.href.split('?')
  if (query.length > 1) {
    let params = {}
    query.slice(1).forEach((item) => {
      let parseString = item
      if (item.indexOf('#') > -1) {
        // eslint-disable-next-line prefer-destructuring
        parseString = item.split(/\/?#/)[0]
      }
      params = {
        ...queryString.parse(parseString),
        ...params
      }
    })
    return params
  }
  return {}
}

export function urlToList(url) {
  const urllist = url.split('/').filter((i) => i)
  return urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join('/')}`)
}

/**
 * url 传参的值 bool 化，显示传 0 表示 false 其余 true
 */
export function checkStringBool(val) {
  return val !== '0'
}

/**
 * 去除域名，截取path
 * @param url
 * @returns {string}
 */
export const getUrlRelativePath = (url) => {
  if(url.indexOf('//') === -1) {
    return url
  }

  const arrUrl = url.split('//')
  const start = arrUrl[1].indexOf('/')
  let relUrl = arrUrl[1].substring(start)

  if (relUrl.indexOf('?') !== -1) {
    relUrl = relUrl.split('?')[0]
  }
  // 特殊处理，不需要带v0.1/static前缀
  if (/\/v0.1\/static/.test(relUrl)) {
    relUrl = relUrl.replace(/\/v0.1\/static/, '')
  }
  return decodeURI(relUrl)
}
