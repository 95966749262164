
import { bizCourseGatewayHost } from 'cfg/bizCourse'
import REST from 'utils/rest';

const bizCourseGateway = new REST(bizCourseGatewayHost);

bizCourseGateway.detail = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/manage_infos');
bizCourseGateway.getManagementPlugins = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/plugins/management/action/list');
bizCourseGateway.getPlugins = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/plugins/management/action/list');
bizCourseGateway.getStudents = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/users');
bizCourseGateway.getPluginExtendDisplays = bizCourseGateway.endpoint('v1/business_courses/{course_id}/modules/{module}/plugin_extend_displays');
bizCourseGateway.exportUser = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/users/export');
bizCourseGateway.updateDetail = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}');
bizCourseGateway.getUploadTokens = bizCourseGateway.endpoint('v1/cs_common/tokens');
bizCourseGateway.createCourse = bizCourseGateway.endpoint('v1/business_courses');

// 用于后台考试管理获取考试列表
bizCourseGateway.getExamManageInfo = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/exam_manage_infos');
// 启用禁用课程下的考试
bizCourseGateway.changeExamEnable = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/exams/{exam_id}/actions/change_enable');
// 删除考试
bizCourseGateway.deleteExam = bizCourseGateway.endpoint('v1/business_courses/{business_course_id}/exams/{exam_id}');

export default bizCourseGateway;
