/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getWorkDetail } from '../actions'
// import { Tabs, Button } from 'fish'
import BaseWork from './baseWork'
// import AdvsWork from './advsWork'
import './index.less'

// const { TabPane } = Tabs

function AddWorkInner () {
  // const changeTab = (_key) => {
  //   console.log(_key)
  // }
  const { wid } = useParams()

  const [detail, setDetail] = useState()

  useEffect(() => {
    if (wid) {
      // 编辑
      getWorkDetail(wid).then((res) => {
        setDetail(res)
      })
    }
  }, [])

  return (
    <div className="_teacher_work_add">
      <BaseWork detail={detail} />
      {/* <Tabs defaultActiveKey="1" onChange={changeTab}>
        <TabPane tab="添加作业" key="1">
          <BaseWork ref={baseWorkRef} />
        </TabPane>
        <TabPane tab="高级设置" key="2">
          <AdvsWork ref={advsWorkRef} />
        </TabPane>
      </Tabs>
      <div style={{
        textAlign: 'center'
      }}
      >
        <Button>
          取消
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          onClick={async () => {
            const baseWorkData = await baseWorkRef.current?.getData()
            const advsWorkData = await advsWorkRef.current?.getData()
            
            // actions.createWork(values).then(async ({
            //   work_id,
            //   title
            // }) => {
            //   // 课程和作业关联
            //   const res = await actions.createRelation({
            //     course_id: window.bizCourseId,
            //     work_id,
            //     title
            //   })
            //   if (res) {
            //     message.success('添加作业成功')
            //   }
            // })
          }}
        >
          保存
        </Button>
      </div> */}
    </div>
  )
}

export default AddWorkInner