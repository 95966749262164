import { env } from './env'

export const BizCourseGateway = {
  'aom-ai-beta': 'https://x-course.th2-staging.apse1.ndpg.xyz'
}

export const BizCourseAPI = {
  'aom-ai-beta': 'https://x-course-api.th2-staging.apse1.ndpg.xyz'
}

export const FeedBookApi = {
  'aom-ai-beta': 'http://audit-gateway.th2-staging.apse1.ndpg.xyz'
}

export const AuditApi = {
  'aom-ai-beta': 'http://audit-gateway.th2-staging.apse1.ndpg.xyz'
}

export const bizCourseGatewayHost = BizCourseGateway[env]
export const bizCourseAPIHost = BizCourseAPI[env]
export const feedBookHost = FeedBookApi[env]
export const auditApiHost = AuditApi[env]
