import { handleActions } from 'redux-actions';
import * as T from './actionTypes';

const ExamDetail = handleActions(
  {
    [T.GET_EXAM_DETAIL]: (state, action) => {
      return {
        ...action.payload
      };
    }
  },
  null
);

export { ExamDetail };
