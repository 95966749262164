import { env } from './env'

export const UCConfig = {
  'aom-ai-beta': {
    host: 'uc-component.th2-staging.apse1.ndpg.xyz',
       origins: {
      UC: 'https://uc-gateway.th2-staging.apse1.ndpg.xyz',
      SSO: 'https://uc-sso.th2-staging.apse1.ndpg.xyz'
    },

  }
}

export const ucConfig = UCConfig[env] || {}
