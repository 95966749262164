import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import ThunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import asyncPromiseMiddleware from '@sdp.nd/redux-async-promise';
import rootReducer from './reducers';

const finalCreateStore = compose(
  applyMiddleware(
    ThunkMiddleware,
    ...asyncPromiseMiddleware({
      // 用于控制是否派发 globalLoadingActionType, 默认值，每个 action 可以在 meta 里面覆盖
      showLoading: true,
      // 全局 actionType 后缀，分别表示三个状态 pending, success, error
      actionTypeSuffixes: ['PENDING', 'SUCCESS', 'ERROR'],
      // 全局 loading action type
      globalLoadingActionType: 'RECEIVE_LOADING_STATE',
      // 全局提示消息 action type
      globalMessageActionType: 'RECEIVE_GLOBAL_MESSAGE'
    }),
    createLogger()
  )
)(createStore);
const reducer = combineReducers({ ...rootReducer });

export default function configureStore(initialState) {
  const store = finalCreateStore(reducer, initialState);
  return store;
}
