const variables = {
  'zh-CN': {},
  'zh-HK': [],
  'en-US': require('fish/lib/locale-provider/en_US'),
  en: require('fish/lib/locale-provider/en_US')
};

exports['en'] = variables['en'];
exports['zh-HK'] = variables['zh-HK'];
exports['zh-CN'] = variables['zh-CN'];
exports['en-US'] = variables['en-US'];
