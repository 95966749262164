import './globalEnv';
import React from 'react';
import { Icon } from 'fish';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import * as $dp from 'dataProvider';
import { getQueryStringByName } from 'modules/common/urlHelper';
import configureStore from './store';
import Routes from './routes';

import 'theme/styles/index.css';
import 'theme/blue/index.less';
import Login from './component/Login'

Icon.url = '/fish/icon/umd-1.2.1.js'

if (typeof global.Promise === 'undefined') {
  require('es6-promise/auto');
}

window.global = decodeURIComponent(getQueryStringByName('global'));
window.source = decodeURIComponent(getQueryStringByName('source'));
window.contextId = decodeURIComponent(getQueryStringByName('context_id'));
window.bizType = decodeURIComponent(getQueryStringByName('biz_type'));
window.returnUrl = decodeURIComponent(getQueryStringByName('return_url'));
window.locale = decodeURIComponent(getQueryStringByName('lang')) || 'zh-CN';
window.bizCourseId = (window.location.pathname.match(/\/frame\/([^/]+)/i) || '')[1] || ''

const {bizType} = window;

const store = configureStore();

new Promise((resolve) => {
  if (bizType) {
    const sdpAppId = window.eduBasicLib.getAppData()['sdp-app-id'];
    let template = window.sessionStorage.getItem(`bizCourseTemplate_${bizType}_${sdpAppId}`);
    if (window.xx && template) {
      try {
        store.dispatch({
          type: 'get_biz_course_template',
          payload: JSON.parse(template)
        });
        resolve();
      } catch (e) {
        // parse error
        resolve();
      }
    } else {
      $dp.bizCourseService.getCourseTemplate
        .replace('biz_type', bizType)
        .get()
        .then((_template) => {
          if(_template) {
            window.sessionStorage.setItem(`bizCourseTemplate_${bizType}_${sdpAppId}`, JSON.stringify(_template));
            store.dispatch({
              type: 'get_biz_course_template',
              payload: _template
            });
            resolve();
          } else {
            throw new Error('no biz-template')
          }
        });
    }
  } else {
    resolve();
  }
}).then(() => {
    ReactDom.render(
      <Login>
        <Provider store={store}>
          <Routes />
        </Provider>
      </Login>,
      document.getElementById('app')
    );
});
