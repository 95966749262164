import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Card, Button, Input } from 'fish';

import message from '@sdp.nd/edu-message';
import { StandardLayout } from 'modules/layouts/standardLayout';
import { getQueryStringByName } from 'modules/common/urlHelper'
import getReturnUrl from 'utils/getReturnUrl'
import getDownloadUrl from 'utils/getDownloadUrl'
import Cover from './component/cover';
import RichText from './component/richText';
import * as actions from './actions';
import MultiFile from './component/multi-file'
import { checkExtendReadingEnable } from './share/util'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 14 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};
@StandardLayout({ title: 'key:create.title' })
@injectIntl
class CreateForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      enableExtendReading: checkExtendReadingEnable(props.bizCourseTemplate),
      saving: false
    };
  }

  onSubmit = (e) => {
    const { intl, form, createCourse } = this.props;
    const { enableExtendReading } = this.state
    this.setState({ saving: true });
    // eslint-disable-next-line no-console
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let options = {
          query: {
            biz_type: getQueryStringByName('biz_type')
          },
          data: {
            context_id: decodeURIComponent(getQueryStringByName('context_id')),
            introduction: values.introduction ? values.introduction : '',
            front_cover_object_id: values.cover ? values.cover.front_cover_object_id : null,
            name: values.name,
            ...enableExtendReading && values?.['extend_reading']?.fileList?.length ? {
              ext_info: {
                extend_reading: values['extend_reading'].fileList.map((t) => ({
                  name: t.name,
                  url: getDownloadUrl(t.response.path),
                  size: t.size
                }))
              }
            } : {}
          }
        };
        createCourse(options).then(
          (res) => {
            this.setState({ saving: false });
            message.success(intl.formatMessage({ id: 'passrule.saveSuccess', defaultMessage: '保存成功！' }));
            res = res.payload ? res.payload : res;

            // cache id
            const { id } = res
            const bizType= getQueryStringByName('biz_type')
            window.sessionStorage.setItem(`${bizType}-id`, id)

            const returnUrl = getReturnUrl()
            let hasParams = returnUrl.indexOf('?') >= 0;
            if (window.source === 'resouces' || window.source === '' || window.global === '1') {
              window.location = `${returnUrl}${hasParams ? '&' : '?'}id=${res.id}`;
            } else if (window.source === 'channel') {
              window.parent.postMessage(
                JSON.stringify({
                  type: 'return_url',
                  return_url: `${returnUrl}${hasParams ? '&' : '?'}id=${res.id}`
                }),
                '*'
              );
            } else {
              window.location = `${returnUrl}${hasParams ? '&' : '?'}id=${res.id}`;
            }
          },
          (error) => {
            const errorInfo = JSON.parse(error.responseText || "{}")
            message.error(errorInfo.message || '请求失败');
            this.setState({ saving: false });
          }
        );
      } else {
        this.setState({ saving: false });
      }
    });
  };

  onCancel = () => {
    const returnUrl = getReturnUrl()
    if (returnUrl) {
      if (window.source === 'channel') {
        window.parent.postMessage(
          JSON.stringify({
            type: 'return_url',
            return_url: returnUrl
          }),
          '*'
        );
      }
      window.location.href = returnUrl;
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { enableExtendReading } = this.state
    return (
      <Card bordered={false} className="edit">
        <Form labelCol={formItemLayout.labelCol} wrapperCol={formItemLayout.wrapperCol} onSubmit={this.onSubmit}>
          <Form.Item label={<FormattedMessage id="create.form.label.cover" defaultMessage="课程封面" />}>
            {getFieldDecorator('cover')(
              <Cover getUploadTokens={this.props.getUploadTokens} />)}
          </Form.Item>
          <Form.Item label={<FormattedMessage id="create.form.label.title" defaultMessage="课程标题" />}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id="create.form.rule.required.title" defaultMessage="课程名称不可为空" />
                }
              ]
            })(<Input maxLength={200} placeholder={this.props.intl.formatMessage({ id: 'create.form.label.title', defaultMessage: '课程标题' })} />)}
          </Form.Item>
          <Form.Item label={<FormattedMessage id="create.form.label.introduction" defaultMessage="课程介绍" />}>
            {getFieldDecorator('introduction', {
              rules: [
                {
                  max: 20000,
                  message: <FormattedMessage id="create.form.rule.max.introduction" defaultMessage="最大长度不能超过20000字符（长度以html代码字符长度为准）" />
                }
              ]
            })(<RichText getUploadTokens={this.props.getUploadTokens} />)}
          </Form.Item>
          {
            enableExtendReading && (
              <Form.Item label={<FormattedMessage id="create.form.label.extendReading" defaultMessage="延伸阅读" />}>
                {getFieldDecorator('extend_reading')(
                  <MultiFile getUploadTokens={this.props.getUploadTokens} />)}
              </Form.Item>
            )
          }
          <Form.Item {...tailFormItemLayout}>
            <Button shape="round" disabled={this.state.saving} onClick={this.onCancel}>
              <FormattedMessage id="create.form.button.cancel" defaultMessage="取消" />
            </Button>
            &nbsp;
            <Button shape="round" type="primary" htmlType="submit" loading={this.state.saving} disabled={this.state.saving}>
              <FormattedMessage id="create.form.button.save" defaultMessage="保存" />
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

CreateForm.propTypes = {
  form: PropTypes.any.isRequired,
  createCourse: PropTypes.func.isRequired,
  getUploadTokens: PropTypes.func.isRequired,
  intl: PropTypes.object,
  bizCourseTemplate: PropTypes.object
};

export default connect(
  (state) => {
    return {
      ...state
    };
  }, {
  ...actions
})(
  Form.create({
    mapPropsToFields: () => {
      let formset = {
        name: Form.createFormField({ value: '' }),
        cover: Form.createFormField({ value: null }),
        introduction: Form.createFormField({ value: '' })
      };

      return formset;
    }
  })(CreateForm)
);
