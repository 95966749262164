/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Radio, InputNumber, DatePicker } from 'fish';
import moment from 'moment';

const { RangePicker } = DatePicker;

@injectIntl
class CourseDatePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      study_time_limit_type: `${props.value ? props.value.study_time_limit_type : 1}`,
      study_start_time: props.value ? props.value.study_start_time : null,
      study_end_time: props.value ? props.value.study_end_time : null,
      study_days: props.value ? props.value.study_days : null
    };
  }

  componentDidMount() {
    const specifiedValue = this.props.customForm && this.props.customForm.type === 'specified' && this.props.customForm.specified_value
    if (specifiedValue) {
      this.setState({ study_time_limit_type: `${specifiedValue}` }, () => {
        this.triggerChange({ ...this.state });
      });
    }
  }

  triggerChange = (changedValue) => {
    switch (changedValue.study_time_limit_type) {
      case '1':
        changedValue = { ...changedValue, ...{ study_time_limit_type: 1, study_start_time: null, study_end_time: null, study_days: null } };
        break;
      case '2':
        changedValue = { ...changedValue, ...{ study_time_limit_type: 2, study_days: null } };
        break;
      case '3':
        changedValue = { ...changedValue, ...{ study_time_limit_type: 3, study_start_time: null, study_end_time: null } };
        break;
    }

    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue
      });
    }
  };

  onDateRangChange = (dates) => {
    this.setState(
      {
        study_start_time: dates[0] && dates[0].format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
        study_end_time: dates[1] && dates[1].format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
      },
      () => {
        this.triggerChange({ ...this.state });
      }
    );
  };

  onDaysChange = (value) => {
    this.setState(
      {
        study_days: value
      },
      () => {
        this.triggerChange({ ...this.state });
      }
    );
  };

  onRadioChange = (e) => {
    this.setState({ study_time_limit_type: e.target.value }, () => {
      this.triggerChange({ ...this.state });
    });
  };

  limitNumber = (value) => {
    if (typeof value === 'string') {
      return !Number.isNaN(Number(value)) ? value.replace(/\./g, '') : 0;
    } else if (typeof value === 'number') {
      return !Number.isNaN(value) ? String(value).replace(/\./g, '') : 0;
    } else {
      return 0;
    }
  };

  render() {
    const { study_time_limit_type, study_start_time, study_end_time, study_days } = this.state;
    const { disabled } = this.props
    let defaultDateRang = study_start_time && study_start_time ? [moment(study_start_time), moment(study_end_time)] : [];
    const specifiedValue = this.props.customForm && this.props.customForm.type === 'specified' && this.props.customForm.specified_value
    return (
      <div className="course-datepicker">
        <Radio.Group onChange={this.onRadioChange} value={study_time_limit_type} disabled={disabled}>
          {
            specifiedValue && `${specifiedValue}` !== '1'
              ? null
              : <Radio value="1"><FormattedMessage id="courseDatePicker.byunlimited" defaultMessage="不限时" /></Radio>
          }
          {
            specifiedValue && `${specifiedValue}` !== '2'
              ? null
              : <Radio value="2"><FormattedMessage id="courseDatePicker.byrang" defaultMessage="按区间" /></Radio>
          }
          {
            specifiedValue && `${specifiedValue}` !== '3'
              ? null
              : <Radio value="3"><FormattedMessage id="courseDatePicker.bydays" defaultMessage="按天数" /></Radio>
          }
        </Radio.Group>
        <div>
          {study_time_limit_type === '3' && <InputNumber disabled={disabled} formatter={this.limitNumber} parser={this.limitNumber} precision={0} onChange={this.onDaysChange} value={study_days} min={1} max={99999} style={{ width: 150 }} />}
          {study_time_limit_type === '2' && <RangePicker disabled={disabled} showTime onChange={this.onDateRangChange} format="YYYY-MM-DD HH:mm:ss" defaultValue={defaultDateRang} />}
        </div>
      </div>
    );
  }
}

CourseDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  customForm: PropTypes.object,
  disabled: PropTypes.bool
};

export default CourseDatePicker;
