import { handleActions } from 'redux-actions';

const bizCourseTemplate = handleActions(
  {
    'get_biz_course_template': (state, action) => {
      return action.payload;
    }
  },
  null
);

export { bizCourseTemplate };
