import React from 'react';

export const SkinContext = React.createContext({ theme: 'blue' });

export const SkinComponent = (WrappedComponent) =>
  class extends React.Component {
    render() {
      return (
        <SkinContext.Consumer>
          {({ theme }) => {
            return <WrappedComponent {...this.props} theme={theme} />;
          }}
        </SkinContext.Consumer>
      );
    }
  };
