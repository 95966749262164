import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Upload, Spin, Button } from 'fish';
import message from '@sdp.nd/edu-message';

import './video.less';
import { cdnHost } from 'cfg/env'

const DEFAULT_COVER_URL = '//s11.e.99.com/auxo/common/images/default_video_cover.png';

@injectIntl
class IntroVideo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      percent: 0,
      videoUrl: props.value && props.value.introduce_video_id ? props.value.introduce_video_url : DEFAULT_COVER_URL,
      videoId: props.value ? props.value.introduce_video_id : null,
      uploadInfo: {}
    };
  }

  getUploadProps = () => {
    const { intl } = this.props;

    const props = {
      name: 'file',
      fileSize: 1024 * 1024 * 1024,
      fileType: '.mp4',
      accept: 'video/mp4',
      multiple: false,
      showUploadList: false,
      data: this.getData,
      action: this.getAction,
      beforeUpload: this.beforeUpload,
      onError: this.handleError,
      onChange: (info) => {
        const { status, response, name } = info.file;
        if (status === 'uploading') {
          this.setState({ uploading: true, percent: info.file.percent || 0 });
        }
        if (status === 'done') {
          let videoUrl = `${cdnHost}/v0.1/download?dentryId=${response.dentry_id}&name=${name}`;
          this.setState(
            {
              uploading: false,
              percent: info.percent || 0,
              videoId: info.file.response.dentry_id,
              videoUrl: videoUrl

            },
            () => {
              let changedValue = { introduce_video_id: info.file.response.dentry_id, introduce_video_url: videoUrl };
              this.triggerChange(changedValue);
            }
          );
        } else if (status === 'error') {
          this.setState({ uploading: false, percent: info.percent || 0 });
          message.success(intl.formatMessage({ id: 'upload.fail', defaultMessage: '上传失败！' }));
        }
      }
    };

    return props;
  };

  getData = (file) => {
    return {
      name: file.name,
      scope: 1,
      path: this.state.uploadInfo.path
    };
  };

  getAction = () => {
    return new Promise((resolve) => {
      this.props.getUploadTokens().then(res => {
        let uploadInfo = res.payload ? res.payload : res
        this.setState({ uploadInfo }, () => {
          let uploadUrl = `//${uploadInfo.server_url
            }/v0.1/upload?token=${encodeURIComponent(uploadInfo.token)
            }&policy=${uploadInfo.policy}&date=${encodeURIComponent(uploadInfo.date_time)}`;
          resolve(uploadUrl);
        });
      });
    });
  };

  beforeUpload = () => {
    this.setState({ percent: 0 });
  };

  getUploadPercent = () => {
    return `${this.state.percent.toFixed(0)}%`;
  };

  handleError = (file, code) => {
    const { intl } = this.props;
    if (code === -130) {
      message.success(intl.formatMessage({ id: 'introVideo.upload.fileType', defaultMessage: '只能上传mp4文件！' }));
    }
    if (code === -110) {
      message.success(intl.formatMessage({ id: 'introVideo.upload.fileSize', defaultMessage: '图片文件不能大于500MB！' }));
    }
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue
      });
    }
  };

  render() {
    const uploadProps = this.getUploadProps();
    const { uploading, videoId, videoUrl } = this.state;
    const tip = this.getUploadPercent();
    const { disabled } = this.props

    return (
      <React.Fragment>
        <div className="video__image">
          <Spin spinning={uploading} tip={tip}>
            {videoId && <video src={videoUrl} controls="controls" />}
            {!videoId && <img alt="video" src={videoUrl} />}
          </Spin>
        </div>
        <div className="video__action__btns">
          <Upload {...uploadProps}>
            <Button disabled={uploading || disabled} shape="round">
              <FormattedMessage id="introVideo.action.uploadVideo" defaultMessage="上传视频" />
            </Button>
          </Upload>
        </div>
        <div className="video__action__tip">
          <p>
            <FormattedMessage id="introVideo.tip.fileType" defaultMessage="* 文件大小不超过500M, 仅支持MP4格式" />
          </p>
        </div>
      </React.Fragment>
    );
  }
}

IntroVideo.propTypes = {
  getUploadTokens: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default IntroVideo;
