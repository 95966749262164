import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { InputNumber, Checkbox } from 'fish';

class PeriodInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = { disabled: !(props.value >= 0), defaultValue: props.value };
  }

  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  onCheckChange = (e) => {
    this.setState(
      (prevState) => {
        return {
          disabled: !e.target.checked,
          defaultValue: !e.target.checked ? prevState.defaultValue : 0
        };
      },
      () => {
        this.triggerChange(e.target.checked ? this.state.defaultValue : -1);
      }
    );
  };

  onHourChange = (value) => {
    this.setState({ defaultValue: value }, () => {
      this.triggerChange(value);
    });
  };

  render() {
    const { disabled, defaultValue } = this.state;

    return (
      <span>
        <Checkbox onChange={this.onCheckChange} checked={!disabled}>
          <FormattedMessage id="period.periodinput.artificialAdjustmentLabel" defaultMessage="人为调整为" />
        </Checkbox>
        <InputNumber style={{ width: '150px' }} disabled={disabled} max={500000} min={0} precision={2} value={defaultValue} onChange={this.onHourChange} />
        &nbsp;
        <FormattedMessage id="period.periodinput.period" defaultMessage="学时" />
      </span>
    );
  }
}

PeriodInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PeriodInput;
