/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import LoadingComponent from 'modules/common/loading';

import { Pagination } from 'fish'
import { getWorkList, getWorkResultList } from '../actions';
import ResultTable from './table';
import WorkResultSearch from './search'

@LoadingComponent
class WorkResult extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      current: 1,
      limit: 10,
      total: 0,
      query: {},
      works: []
    }
    window.document.title = '作业成果管理'
  }

  init = (_data = this.state.query, _page = this.state.current) => {
    getWorkResultList(_data, {
      limit: this.state.limit,
      offset: (_page - 1) * this.state.limit
    }).then((res) => {
      this.setState({
        data: res.items,
        total: res.total
      })
      this.props.setLoading(false);
    })
  }

  onChange = (page) => {
    this.setState({
      current: page
    })
    this.init(this.state.query, page)
  }

  // 获取全部作业
  getAllWorkList = async () => {
    const maxLimit = 100
    const _remoteDatas = await getWorkList({
      limit: maxLimit,
      offset: 0
    })
    if (_remoteDatas.total > maxLimit) {
      const promises = []
      for (let i = maxLimit; i < _remoteDatas.total; i += maxLimit) {
        promises.push(i)
      }
      const res = await Promise.all(promises.map(async (_p) => {
        const t = await getWorkList({
          limit: maxLimit,
          offset: _p
        })
        return t
      }))
      let _datas = [
        ..._remoteDatas.items
      ]
      res.forEach((r) => {
        _datas = [
          ..._datas,
          ...r.items
        ]
      })
      return _datas
    } else {
      return _remoteDatas.items
    }
  }

  componentDidMount() {
    this.init()
    // 取所有作业列表
    this.getAllWorkList().then((_items) => {
      this.setState({
        works: _items
      })
    })
  }

  render() {
    const { current, limit, total, works } = this.state
    return (
      <div style={{
        padding: 10
      }}
      >
        <WorkResultSearch onSearch={(v, callback) => {
          this.setState({
            query: v,
            current: 1
          })
          this.init(v, 1)
          setTimeout(() => {
            callback()
          }, 1000)
        }}
        />
        <p>
          &nbsp;
        </p>
        <ResultTable data={this.state.data} onLoad={this.init} works={works} />
        <p>
          &nbsp;
        </p>
        <Pagination showQuickJumper current={current} pageSize={limit} total={total} onChange={this.onChange} />
      </div>
    )
  }
}

export default WorkResult
