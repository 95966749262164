export const defaultMenus = {
  back: {
    id: 'biz-course',
    title: '返回',
    i18n: 'menu.back',
    url: '{return_url}'
  },
  catalog: [
    {
      id: 'biz-course-info',
      title: '课程信息',
      i18n: 'menu.course.info',
      class: '',
      children: [
        {
          id: 'biz-course-edit',
          title: '基本信息',
          i18n: 'menu.course.info.edit',
          descrption: '',
          class: '',
          url: '/frame/:id/edit?sdpLibraryScopeType={sdpLibraryScopeType}&audit_policy={audit_policy}&library_id={library_id}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-content',
          title: '课程内容',
          i18n: 'menu.course.info.content',
          descrption: '',
          class: '',
          url: '/frame/:id/content?sdpLibraryScopeType={sdpLibraryScopeType}&audit_policy={audit_policy}&library_id={library_id}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-passrule',
          title: '通过条件',
          i18n: 'menu.course.info.passrule',
          descrption: '',
          class: '',
          url: '/frame/:id/pass_rule?sdpLibraryScopeType={sdpLibraryScopeType}&audit_policy={audit_policy}&library_id={library_id}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-period',
          title: '学时设置',
          i18n: 'menu.course.info.period',
          descrption: '',
          class: '',
          url: '/frame/:id/period?sdpLibraryScopeType={sdpLibraryScopeType}&audit_policy={audit_policy}&library_id={library_id}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-qrcode',
          title: '二维码',
          i18n: 'menu.course.info.qrcode',
          descrption: '',
          class: '',
          url: '/frame/:id/qrcode?sdpLibraryScopeType={sdpLibraryScopeType}&audit_policy={audit_policy}&library_id={library_id}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-work',
          title: '作业管理',
          i18n: 'menu.course.info.work',
          descrption: '',
          class: '',
          url: '/frame/:id/work?sdpLibraryScopeType={sdpLibraryScopeType}&audit_policy={audit_policy}&library_id={library_id}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-qa',
          title: '讨论管理',
          i18n: 'menu.course.info.qa',
          descrption: '',
          class: '',
          url: '/frame/:id/qa?sdpLibraryScopeType={sdpLibraryScopeType}&audit_policy={audit_policy}&library_id={library_id}&biz_type={biz_type}&global={global}&source={channel}&activity_set_id={activity_set_id}&return_url={return_url}'
        },
        {
          id: 'biz-course-more',
          title: '更多管理',
          i18n: 'menu.course.info.more',
          descrption: '',
          class: '',
          url: '/frame/:id/more?sdpLibraryScopeType={sdpLibraryScopeType}&audit_policy={audit_policy}&library_id={library_id}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        }
      ]
    }
  ]
};

export const offlineExamMenu = {
  back: {
    id: 'biz-course',
    title: '返回',
    i18n: 'menu.back',
    url: ':returnUrl'
  },
  catalog: [
    {
      id: 'biz-course-offlineExam',
      title: '考试信息',
      i18n: 'menu.course.offlineExam',
      class: '',
      children: [
        {
          id: 'biz-course-offlineExam-edit',
          title: '基本信息',
          i18n: 'menu.course.offlineExam.edit',
          descrption: '',
          class: '',
          url: '/admin/course/:id/exam/:examId/edit?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-offlineExam-enrollsetting',
          title: '报名设置',
          i18n: 'menu.course.offlineExam.enrollsetting',
          class: '',
          url: '/admin/course/:id/exam/:examId/enroll_setting?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-offlineExam-enrollmanage',
          title: '报名管理',
          i18n: 'menu.course.offlineExam.enrollmanage',
          class: '',
          url: '/admin/course/:id/exam/:examId/enroll_manage?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-offlineExam-pay',
          i18n: 'menu.course.offlineExam.pay',
          title: '缴费管理',
          class: '',
          url: '/admin/course/:id/exam/:examId/pay?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-offlineExam-score',
          i18n: 'menu.course.offlineExam.score',
          title: '成绩录入',
          class: '',
          url: '/admin/course/:id/exam/:examId/score_import?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        }
      ]
    }
  ]
};

export const onlineExamMenu = {
  back: {
    id: 'biz-course',
    title: '返回',
    i18n: 'menu.back',
    url: '{{return_url}}'
  },
  catalog: [
    {
      id: 'biz-course-onlineExam',
      title: '考试信息',
      i18n: 'menu.course.onlineExam',
      class: '',
      children: [
        {
          id: 'biz-course-onlineExam-edit',
          title: '基本信息',
          i18n: 'menu.course.onlineExam.edit',
          descrption: '',
          class: '',
          url: '/admin/course/:id/exam/:examId/edit?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-strategy',
          title: '试卷策略',
          i18n: 'menu.course.onlineExam.strategy',
          descrption: '',
          class: '',
          url: '/admin/course/:id/exam/:examId/strategy?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-enrollsetting',
          title: '报名设置',
          i18n: 'menu.course.onlineExam.enrollsetting',
          class: '',
          url: '/admin/course/:id/exam/:examId/enroll_setting?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-enrollmanage',
          i18n: 'menu.course.onlineExam.enrollmanage',
          title: '报名管理',
          class: '',
          url: '/admin/course/:id/exam/:examId/enroll_manage?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-pay',
          i18n: 'menu.course.onlineExam.pay',
          title: '缴费管理',
          class: '',
          url: '/admin/course/:id/exam/:examId/pay?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-sessionManage',
          i18n: 'menu.course.onlineExam.sessionManage',
          title: '场次管理',
          class: '',
          url: '/admin/course/:id/exam/:examId/session_manage?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-statistics',
          i18n: 'menu.course.onlineExam.statistics',
          title: '统计分析',
          class: '',
          url: '/admin/course/:id/exam/:examId/statistics?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-passRule',
          i18n: 'menu.course.onlineExam.passRule',
          title: '通过条件',
          class: '',
          url: '/admin/course/:id/exam/:examId/pass?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-advancedSetting',
          i18n: 'menu.course.onlineExam.advancedSetting',
          title: '高级设置',
          class: '',
          url: '/admin/course/:id/exam/:examId/setting?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-markSetting',
          i18n: 'menu.course.onlineExam.markSetting',
          title: '批改设置',
          class: '',
          url: '/admin/course/:id/exam/:examId/mark_setting?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        },
        {
          id: 'biz-course-onlineExam-scoreSetting',
          i18n: 'menu.course.onlineExam.scoreSetting',
          title: '成绩设置',
          class: '',
          url: '/admin/course/:id/exam/:examId/score_setting?exam_type={examType}&biz_type={biz_type}&global={global}&source={channel}&return_url={return_url}'
        }
      ]
    }
  ]
};

export const webfrontMenus = {
  catalog: [
    {
      id: 'biz-course-edit',
      title: '基本信息',
      i18n: 'menu.course.info.edit',
      class: '',
      url: '/biz-course/webfront-admin/course/:id?biz_type={biz_type}&global={global}&source={channel}&entry_type={entry_type}&nomenu={nomenu}&review={review}&editable={editable}&return_url={return_url}'
    },
    {
      id: 'biz-course-content',
      title: '课程内容',
      i18n: 'menu.course.info.content',
      class: '',
      url: '/biz-course/webfront-admin/course/:id/content?biz_type={biz_type}&global={global}&source={channel}&entry_type={entry_type}&nomenu={nomenu}&review={review}&editable={editable}&return_url={return_url}'
    },
    {
      id: 'biz-course-passrule',
      title: '通过条件',
      i18n: 'menu.course.info.passrule',
      class: '',
      url: '/biz-course/webfront-admin/course/:id/pass?biz_type={biz_type}&global={global}&source={channel}&entry_type={entry_type}&nomenu={nomenu}&review={review}&editable={editable}&return_url={return_url}'
    },
    {
      id: 'biz-course-students',
      title: '学员管理',
      i18n: 'menu.course.info.students',
      class: '',
      url: '/biz-course/webfront-admin/course/:id/student?biz_type={biz_type}&global={global}&source={channel}&entry_type={entry_type}&nomenu={nomenu}&review={review}&editable={editable}&return_url={return_url}'
    },
    {
      id: 'biz-course-period',
      title: '学时设置',
      i18n: 'menu.course.info.period',
      class: '',
      url: '/biz-course/webfront-admin/course/:id/setting?biz_type={biz_type}&global={global}&source={channel}&entry_type={entry_type}&nomenu={nomenu}&review={review}&editable={editable}&return_url={return_url}'
    }
  ]
};

export const webfrontReviewMenus = {
  catalog: [
    {
      id: 'biz-course-edit',
      title: '基本信息',
      i18n: 'menu.course.info.edit',
      class: '',
      url: '/biz-course/webfront-admin/course/:id?biz_type={biz_type}&global={global}&source={channel}&entry_type={entry_type}&nomenu={nomenu}&review={review}&editable={editable}&return_url={return_url}'
    },
    {
      id: 'biz-course-content',
      title: '课程内容',
      i18n: 'menu.course.info.content',
      class: '',
      url: '/biz-course/webfront-admin/course/:id/content?biz_type={biz_type}&global={global}&source={channel}&entry_type={entry_type}&nomenu={nomenu}&review={review}&editable={editable}&return_url={return_url}'
    }
  ]
};
