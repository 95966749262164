import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Card, Button, Input, Alert, Modal } from 'fish';
import message from '@sdp.nd/edu-message';
import { AdminMenu } from 'modules/layouts/adminMenu';
import { keyBy } from 'lodash';
import { editable, isWebfront } from 'modules/common/queryString';
import { withRouter } from 'react-router-dom';
import getDownloadUrl from 'utils/getDownloadUrl';
import Cover from './component/cover';
import RichText from './component/richText';
import IntroVideo from './component/video';
import CourseConfig from './component/config';
import MaxPeriod from './component/max-period';
import { checkExtendReadingEnable, checkExtendMaxPeriodEnable } from './share/util';
import * as actions from './actions';
import MultiFile from './component/multi-file';
import { getUrlQuery } from 'utils/url';
import feedBookHostGateway from 'dataProvider/feedBook';
import { Consumer } from '../layouts/adminMenu';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 8,
      offset: 6
    },
    sm: {
      span: 6,
      offset: 6
    }
  }
};

@AdminMenu({ key: 'biz-course-edit' })
@injectIntl
@withRouter
class EditForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      bizCourseId: props.match.params.id,
      enableExtendReading: checkExtendReadingEnable(props.bizCourseTemplate),
      enableMaxPeriod: checkExtendMaxPeriodEnable(props.bizCourseTemplate),
      customForm: props.bizCourseTemplate && props.bizCourseTemplate.custom_form && keyBy(props.bizCourseTemplate.custom_form, 'field')
    };
  }

  async componentDidMount() {
    const { bizCourseId } = this.state;
    this.props.getCourseDetail({ businessCourseId: bizCourseId });
  }

  onSubmit = (e) => {
    const { bizCourseId, enableExtendReading, enableMaxPeriod } = this.state;
    const { intl, form, updateDetail } = this.props;
    e.preventDefault();
    this.setState({ saving: true });

    form.validateFields((err, values) => {
      if (!err) {
        let options = {
          businessCourseId: bizCourseId,
          data: {
            name: values.name,
            front_cover_object_id: values.cover.front_cover_object_id,
            introduce_video_id: values?.video?.introduce_video_id,
            summary: values.summary,
            introduction: values.introduction,
            ...values.quality_course_version ? {
              quality_course_version: values.quality_course_version
            } : {},
            user_suit: values.userSuit,
            study_time_limit_type: values?.config?.study_time_limit_type,
            study_start_time: values?.config?.study_start_time,
            study_end_time: values?.config?.study_end_time,
            study_days: values?.config?.study_days,
            ...enableExtendReading ? {
              ext_info: {
                ...this.props.course.ext_info || {},
                extend_reading: values['extend_reading'].fileList.map((t) => ({
                  name: t.name,
                  url: t.downloadUrl || t.url ||
                    getDownloadUrl(t?.response?.path),
                  size: t.size
                }))
              }
            } : {}
          }
        };
        if (enableMaxPeriod) {
          options.data.ext_info = {
            ...options.data.ext_info || {},
            progress_config: {
              ...this.props.course.ext_info.progress_config,
              max_period: values?.max_period
            }
          };
        } else if (options.data?.ext_info?.progress_config) {
          delete options.data.ext_info.progress_config;
        }
        updateDetail(options).then(
          () => {
            message.success(intl.formatMessage({ id: 'period.updateSuccess', defaultMessage: '更新成功!' }));
            setTimeout(()=>{
              this.props.getCourseDetail({ businessCourseId: bizCourseId });
            },1000)
            //
            this.setState({ saving: false });
          },
          (error) => {
            const errorInfo = JSON.parse(error.responseText || '{}');
            message.error(errorInfo.message || '请求失败');
            this.setState({ saving: false });
          }
        );
      } else {
        this.setState({ saving: false });
      }
    });
  };

  validator = (rule, value, callback) => {
    const { intl } = this.props;
    const { study_time_limit_type, study_start_time, study_days, study_end_time } = value;

    if (study_time_limit_type === 2 && !study_start_time && !study_end_time) {
      callback(intl.formatMessage({ id: 'edit.form.rule.dateRang', defaultMessage: '请设置学习开始以及截止时间范围！' }));
    } else if (study_time_limit_type === 3 && !study_days) {
      callback(intl.formatMessage({ id: 'edit.form.rule.days', defaultMessage: '请设置可学习天数！' }));
    } else {
      callback();
    }
  };


  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form;
    const { course } = this.props;
    const { customForm } = this.state;
    const { enableExtendReading, enableMaxPeriod } = this.state;

    if (!course) return null;
    console.log('course', course);
    const disabled = !editable || (isWebfront && course.status === 1);
    // const { biz_type } = getUrlQuery();

    return (
      <Consumer>
        {
          (value) => {
            return <Card bordered={false}>
              <div>
                <Form labelCol={formItemLayout.labelCol} wrapperCol={formItemLayout.wrapperCol} onSubmit={this.onSubmit}>
                  <Form.Item label={<FormattedMessage id='create.form.label.title' defaultMessage='课程标题' />}>
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id='create.form.rule.required.title' defaultMessage='课程名称不可为空' />
                        }
                      ]
                    })(<Input disabled={disabled} maxLength={200} placeholder={this.props.intl.formatMessage({ id: 'create.form.label.title', defaultMessage: '课程标题' })} />)}
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id='create.form.label.cover' defaultMessage='课程封面' />}>
                    {
                      getFieldDecorator('cover')(
                        <Cover
                          disabled={disabled}
                          violationData={value}
                          getUploadTokens={this.props.getUploadTokens}
                        />)
                    }
                  </Form.Item>
                  {
                    course.quality_course_version && (
                      <Form.Item
                        label='其他信息'
                      >
                        {
                          getFieldDecorator('quality_course_version', {
                            rules: [
                              {
                                required: true,
                                message: '精品课程版本不可为空'
                              }
                            ]
                          })(
                            <Input
                              disabled={disabled}
                              maxLength={50}
                              placeholder='精品课程版本'
                            />
                          )
                        }
                      </Form.Item>
                    )
                  }

                  {customForm && customForm.video && customForm.video.type === 'hide' ? null : (
                    <Form.Item label={<FormattedMessage id='create.form.label.video' defaultMessage='介绍视频' />}>{getFieldDecorator('video')(<IntroVideo disabled={disabled} getUploadTokens={this.props.getUploadTokens} />)}</Form.Item>
                  )}
                  <Form.Item label={<FormattedMessage id='create.form.label.introduction' defaultMessage='课程介绍' />}>
                    {getFieldDecorator('introduction', {
                      rules: [
                        {
                          max: 20000,
                          message: <FormattedMessage id='create.form.rule.max.introduction' defaultMessage='最大长度不能超过20000字符（长度以html代码字符长度为准）' />
                        }
                      ]
                    })(<RichText disabled={disabled} getUploadTokens={this.props.getUploadTokens} />)}
                  </Form.Item>
                  {
                    enableExtendReading && (
                      <Form.Item label={<FormattedMessage id='create.form.label.extendReading' defaultMessage='延伸阅读' />}>
                        {getFieldDecorator('extend_reading')(
                          <MultiFile getUploadTokens={this.props.getUploadTokens} />)}
                      </Form.Item>
                    )
                  }
                  <Form.Item label={<FormattedMessage id='edit.form.label.description' defaultMessage='课程摘要' />}>
                    {getFieldDecorator('summary')(
                      <TextArea
                        style={{ height: '90px' }}
                        maxLength={200}
                        disabled={disabled}
                        showCounter={(curNum, total) => {
                          return (
                            <div className='fish-input-maxnum-wrapper'>
                              <FormattedMessage id='edit.form.numStatistics' defaultMessage='已输入{curNum}字,总共{total}字' values={{ curNum, total }} />
                            </div>
                          );
                        }}
                      />
                    )}
                  </Form.Item>
                  {customForm && customForm.user_suit && customForm.user_suit.type === 'hide' ? null : (
                    <Form.Item label={<FormattedMessage id='edit.form.label.suit' defaultMessage='适合人群' />}>
                      {getFieldDecorator('userSuit')(
                        <TextArea
                          disabled={disabled}
                          style={{ height: '90px' }}
                          maxLength={100}
                          showCounter={(curNum, total) => {
                            return (
                              <div className='fish-input-maxnum-wrapper'>
                                <FormattedMessage id='edit.form.numStatistics' defaultMessage='已输入{curNum}字,总共{total}字' values={{ curNum, total }} />
                              </div>
                            );
                          }}
                        />
                      )}
                    </Form.Item>
                  )}
                  {customForm && customForm.study_time_limit_type && customForm.study_time_limit_type.type === 'hide' ? null : (
                    <Form.Item label={<FormattedMessage id='edit.form.label.datetime' defaultMessage='开课时间' />}>{getFieldDecorator('config', { rules: [{ validator: this.validator }] })(<CourseConfig disabled={disabled} customForm={customForm && customForm.study_time_limit_type} />)}</Form.Item>
                  )}
                  {enableMaxPeriod && <Form.Item label={<FormattedMessage id='edit.form.label.maxPeriod' defaultMessage='可获学时数上限' />}>{getFieldDecorator('max_period')(<MaxPeriod disabled={disabled} />)}</Form.Item>}

                  <Form.Item {...tailFormItemLayout}>
                    {!disabled && (
                      <Button shape='round' type='primary' htmlType='submit' disabled={this.state.saving}>
                        <FormattedMessage id='period.save' defaultMessage='保存' />
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
            </Card>;
          }
        }
      </Consumer>
    );
  }
}

EditForm.propTypes = {
  getUploadTokens: PropTypes.func.isRequired,
  getCourseDetail: PropTypes.func.isRequired,
  updateDetail: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  form: PropTypes.any.isRequired,
  intl: PropTypes.object.isRequired,
  bizCourseTemplate: PropTypes.object,
  match: PropTypes.object
};

export default connect(
  (state) => {
    console.log('statestate',state);
    return {
      course: state.CourseDetail,
      bizCourseTemplate: state.bizCourseTemplate
    };
  },
  {
    ...actions
  }
)(
  Form.create({
    mapPropsToFields: (props) => {
      const { course } = props;
      let config = {
        study_time_limit_type: course ? course.config.study_time_limit_type : 1,
        study_start_time: course ? course.config.study_start_time : null,
        study_end_time: course ? course.config.study_end_time : null,
        study_days: course ? course.config.study_days : null
      };
      let formset = {
        name: Form.createFormField({ value: course ? course.name : '' }),
        cover: Form.createFormField({ value: course ? { front_cover_object_id: course.front_cover_object_id, front_cover_object_url: course.front_cover_object_url } : null }),
        video: Form.createFormField({ value: course ? { introduce_video_id: course.introduce_video_id, introduce_video_url: course.introduce_video_url } : null }),
        quality_course_version: Form.createFormField({ value: course ? course.quality_course_version : '' }),
        introduction: Form.createFormField({ value: course ? course.introduction : '' }),
        extend_reading: Form.createFormField({
          value: {
            fileList: course?.ext_info?.extend_reading || []
          }
        }),
        // 最大可获得学时。-1或者null: 代表不限制
        max_period: Form.createFormField({ value: course?.ext_info?.progress_config?.max_period >= -1 ? course?.ext_info?.progress_config?.max_period : -1 }),
        summary: Form.createFormField({ value: course ? course.summary : '' }),
        userSuit: Form.createFormField({ value: course ? course.user_suit : '' }),
        config: Form.createFormField({ value: config })
      };

      return formset;
    }
  })(EditForm)
);
