/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
import React from 'react'
import {
  Form,
  Input,
  Select,
  Button
} from 'fish'
import { one } from 'utils/one'

const { Option } = Select

class WorkResultSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // users: [],
      loading: false
    }
    // this._handleSearchUserTSMP = null
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        })
        let user_id = values?.user_id
        if (values.title?.trim() === '') {
          delete values.title
        }
        if (values.name?.trim() === '') {
          delete values.name
        }
        if (!values.user_id) {
          delete values.user_id
        } else {
          // 替换用户ID
          const _users = await one.oms.findInstUsers({
            instId: 0,
            keyword: values.user_id
          })
          if (_users?.items?.length > 0) {
            user_id = _users?.items[0].user_id
          }
        }
        if (values.is_open === 2) {
          delete values.is_open
        }
        this.props.onSearch({
          ...values,
          user_id
        }, () => {
          this.setState({
            loading: false
          })
        })
      }
    })
  }

  // handleSearchUser = async (_v) => {
  //   clearTimeout(this._handleSearchUserTSMP)
  //   const loadingUC = async () => {
  //     this.setState({
  //       loading: true
  //     })
  //     const _users = await one.oms.findInstUsers({
  //       instId: 0,
  //       keyword: _v
  //     })
  //     this.setState({
  //       loading: false,
  //       users: _users?.items || []
  //     })
  //   }
  //   this._handleSearchUserTSMP = setTimeout(loadingUC, 300)
  // }

  render () {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state

    return (
      <Form
        layout="inline"
        onSubmit={this.handleSubmit}
        style={{
          display: 'flex',
          margin: '10px'
        }}
      >
        <Form.Item label="作业成果">
          {getFieldDecorator('title')(
            <Input
              placeholder="请输入关键字"
            />
          )}
        </Form.Item>
        <Form.Item label="姓名">
          {getFieldDecorator('name')(
            <Input
              placeholder="请输入完整姓名"
            />
          )}
        </Form.Item>
        <Form.Item label="帐号">
          {getFieldDecorator('user_id')(
            <Input
              placeholder="请输入完整手机号"
            />
            // <Select
            //   showSearch
            //   loading={loading}
            //   style={{
            //     width: '160px'
            //   }}
            //   allowClear
            //   placeholder="请输入完整手机号"
            //   defaultActiveFirstOption={false}
            //   showArrow={false}
            //   filterOption={false}
            //   onSearch={this.handleSearchUser}
            //   notFoundContent={null}
            // >
            //   {users?.map((_user, idx) => <Option key={`${_user.user_id}_${idx}`} value={_user.user_id}>{_user.user_name || _user.nick_name || _user.real_name}</Option>)}
            // </Select>
          )}
        </Form.Item>
        <Form.Item label="推荐">
          {getFieldDecorator('is_open', { initialValue: 2 })(
            <Select
              placeholder="选中一项联动改变备注字段值"
              onChange={this.handleSelectChange}
              style={{
                width: '120px'
              }}
            >
              <Option value={2}>全部</Option>
              <Option value={1}>是</Option>
              <Option value={0}>否</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item style={{
          flex: 1
        }}
        >
          <Button type="primary" htmlType="submit" icon="search" loading={loading}>
            搜索
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create({ name: 'advsWorkForm' })(WorkResultSearch)