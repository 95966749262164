import * as passrules from 'modules/passrule/reducers';
import * as more from 'modules/more/reducers';
import * as access from 'modules/access/reducers';
import * as student from 'modules/student/reducers';
import * as period from 'modules/period/reducers';
import * as exams from 'modules/exams/reducers';
import * as edit from 'modules/edit/reducers';
import * as content from 'modules/content/reducers';
import * as offlineExam from 'modules/offlineExam/reducers';
import * as globalState from '../reducers'

export default { ...passrules, ...more, ...access, ...student, ...period, ...exams, ...edit, ...content, ...offlineExam, ...globalState };
