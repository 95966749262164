import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Intl from 'i18n';
import Qrcode from 'modules/qrcode';
// import PassRule from 'modules/passrule';
import More from 'modules/more';
// import Access from 'modules/access';
// import Student from 'modules/student';
import Content from 'modules/content';
import Period from 'modules/period';
import Work from 'modules/work';
import Qa from 'modules/qa';
// import Exams from 'modules/exams';
// import StudyCtxSelect from 'modules/studyCtxSelect';
// import StudyCtxSelectTest from 'modules/studyCtxSelect/test';
// import OfflineBase from 'modules/offlineExam/base';
// import OfflineExamEnrollSetting from 'modules/offlineExam/enrollSetting';
// import OfflineExamEnrollManage from 'modules/offlineExam/enrollManage';
// import OfflineExamPay from 'modules/offlineExam/pay';
// import OfflineExamScoreImport from 'modules/offlineExam/scoreImport';
// import OnlineExamBase from 'modules/onlineExam/base';
// import OnlineExamEnrollSetting from 'modules/onlineExam/enrollSetting';
// import OnlineExamEnrollManage from 'modules/onlineExam/enrollManage';
// import OnlineExamPay from 'modules/onlineExam/pay';
// import OnlineExamStrategy from 'modules/onlineExam/strategy';
// import OnlineExamSessions from 'modules/onlineExam/sessions';
// import OnlineExamStatistics from 'modules/onlineExam/statistics';
// import OnlineExamPassRule from 'modules/onlineExam/passRule';
// import OnlineExamAdvancedSetting from 'modules/onlineExam/advancedSetting';
// import OnlineExamMarkSetting from 'modules/onlineExam/markSetting';
// import OnlineExamScoreSetting from 'modules/onlineExam/scoreSetting';
import CreateForm from 'modules/edit/create';
import EditForm from 'modules/edit';
import HomePage from 'modules/home'
import AddWork from 'modules/work/add';
import WorkResult from 'modules/work/result';

export default function Routes() {
  return (
    <BrowserRouter>
      <Intl>
        <Switch>
          {/* 创建新课程，只录入基本信息 */}
          <Route path="/" exact component={HomePage} />
          {/* 创建新课程，只录入基本信息 */}
          <Route path="/create" exact component={CreateForm} />
          {/* 编辑课程-课程信息-编辑课程 */}
          <Route path="/frame/:id/edit" exact component={EditForm} />
          {/* 编辑课程-课程信息-课程内容 */}
          <Route path="/frame/:id/content" exact component={Content} />
          {/* 编辑课程-课程信息-通过条件 */}
          {/* <Route path="/frame/:id/pass_rule" exact component={PassRule} /> */}
          {/* 编辑课程-课程信息-二维码 */}
          <Route path="/frame/:id/qrcode" exact component={Qrcode} />
          {/* 编辑课程-课程信息-更多管理 */}
          <Route path="/frame/:id/more" exact component={More} />
          {/* 编辑课程-课程信息-学识设置 */}
          <Route path="/frame/:id/period" exact component={Period} />
          {/* 编辑课程-课程信息-添加作业 */}
          <Route path="/frame/:id/work/add" exact component={AddWork} />
          {/* 编辑课程-课程信息-修改作业 */}
          <Route path="/frame/:id/work/edit/:wid" exact component={AddWork} />
          {/* 编辑课程-课程信息-作业结果 */}
          <Route path="/frame/:id/work/result" exact component={WorkResult} />
          {/* 编辑课程-课程信息-作业管理 */}
          <Route path="/frame/:id/work" exact component={Work} />
          {/* 编辑课程-课程信息-讨论管理 */}
          <Route path="/frame/:id/qa" exact component={Qa} />
          {/* 
          <Route path="/studyCtxSelectTest" exact component={StudyCtxSelectTest} />
          <Route path="/frame/:id/studyCtxSelect" exact component={StudyCtxSelect} />
          <Route path="/frame/:id/access" exact component={Access} />
          <Route path="/frame/:id/students" exact component={Student} />
          <Route path="/frame/:id/period" exact component={Period} />
          <Route path="/frame/:id/exams" exact component={Exams} />
          <Route path="/frame/:id/offline_exam/:examId" exact component={OfflineBase} />
          <Route path="/frame/:id/offline_exam/:examId/enroll_setting" exact component={OfflineExamEnrollSetting} />
          <Route path="/frame/:id/offline_exam/:examId/enroll_manage" exact component={OfflineExamEnrollManage} />
          <Route path="/frame/:id/offline_exam/:examId/pay" exact component={OfflineExamPay} />
          <Route path="/frame/:id/offline_exam/:examId/score_import" exact component={OfflineExamScoreImport} />
          <Route path="/frame/:id/online_exam/:examId" exact component={OnlineExamBase} />
          <Route path="/frame/:id/online_exam/:examId/strategy" exact component={OnlineExamStrategy} />
          <Route path="/frame/:id/online_exam/:examId/enroll_setting" exact component={OnlineExamEnrollSetting} />
          <Route path="/frame/:id/online_exam/:examId/enroll_manage" exact component={OnlineExamEnrollManage} />
          <Route path="/frame/:id/online_exam/:examId/pay" exact component={OnlineExamPay} />
          <Route path="/frame/:id/online_exam/:examId/sessions" exact component={OnlineExamSessions} />
          <Route path="/frame/:id/online_exam/:examId/statistics" exact component={OnlineExamStatistics} />
          <Route path="/frame/:id/online_exam/:examId/pass_rule" exact component={OnlineExamPassRule} />
          <Route path="/frame/:id/online_exam/:examId/advanced_setting" exact component={OnlineExamAdvancedSetting} />
          <Route path="/frame/:id/online_exam/:examId/mark_setting" exact component={OnlineExamMarkSetting} />
          <Route path="/frame/:id/online_exam/:examId/score_setting" exact component={OnlineExamScoreSetting} /> */}
        </Switch>
      </Intl>
    </BrowserRouter>
  );
}
