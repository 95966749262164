import { message, notification } from 'fish'
import { omit } from 'lodash';
import { appId, orgId } from 'cfg/env'
import Request from './request'
import { getAuthHeader } from '../auth/func'

function getURL(c) {
  const { url, originBaseURL } = c
  if (/^https?:/.test(url)) {
    return url
  }
  if (/^https?:/.test(originBaseURL)) {
    return `${originBaseURL}${c.url}`
  }
  return `https:${originBaseURL}${c.url}`
}


export function rawAPI(params) {
  const headers = {
    'sdp-app-id': appId,
    ...orgId ? {
      'sdp-org-id': orgId
    } : {},
    ...(params?.headers || {})
  }
  // 本地构建请求禁用缓存
  if (process.env.DEV) {
    headers['Cache-Control'] = 'no-cache'
  }

  const { host, prefix = '' } = params
  if (host) {
    params.originBaseURL = `${host}${prefix}`
    params.baseURL = params.originBaseURL
    // params.baseURL = process.env.DEV ? `/wds_${hostKey}${prefix}` : params.originBaseURL
  }

  const api = Request.create({
    headers,
    ...omit(params, ['headers'])
  })
  return api
}

/**
 * 用于创建通用 api 请求对象，携带 loading、error 通用处理
 */
export function generateCommonAPI(params) {
  const api = rawAPI({
    loading({ key }) {
      message.loading({
        content: '正在加载数据...',
        duration: 1500,
        key
      })
    },
    error(e) {
      const description = e?.response?.data?.message ?? e?.message ?? '未知异常'
      notification.error({
        message: '请求错误',
        description
      })
    },
    complete({ key }) {
      message.destroy(key)
    },
    ...params
  })
  return api
}

/**
 *  用于创建需要使用 UC 认证的 api，基于 generateCommonAPI
 */
export function generateCommonAPIWithUC(params) {
  const api = generateCommonAPI(params)
  api.interceptors.request.use(async (c) => {
/*     if (c.noAuth) {
      return c
    } */
    // UC 计算 mac token 必须是完整的 URL
    const Authorization = await getAuthHeader({
      ...c,
      url: getURL(c)
    })
    if (Authorization) {
      c.headers.Authorization = Authorization
    }
    return c
  })

  return api
}
